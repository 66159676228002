const localStorageKey = 'giiki-web';
const sessionStorageKey = 'giiki-web';
const keyRecentCampuses = 'campusRecent';
const keyNavbar = 'navbar';
const keyInfo = 'giiki-info';
const giikiAdminId = 1;
const rowsPerPageCount = [5, 10];
const objFieldGiikiInfo = {
  // currentCampus: 'currentCampus',
  // navbarName: 'navbarName',
  // headerName: 'headerName',
  navbar: 'navbar',
  currentCampusOrCompany: 'currentCampusOrCompany',
  campusRecent: 'campusRecent',
  title: 'title',
  menu: 'menu',
  // central: 'central',
};

const getSessionStorage = () => {
  const data = sessionStorage.getItem(sessionStorageKey);
  try {
    return JSON.parse(data);
  } catch {
    return undefined;
  }
};

const updateSessionStorage = (key, payload) => {
  let data = getSessionStorage();
  if (!data) data = {};
  sessionStorage.setItem(sessionStorageKey, JSON.stringify({ ...data, [key]: payload }));
};

const getSessionStorageAuth = () => {
  const data = getSessionStorage();
  if (data) return data?.auth;
  return undefined;
};

const getSessionStorageByKey = (key) => {
  const data = getSessionStorage();
  if (data) return data?.[key];
  return undefined;
};

const getLocalStorage = () => {
  const data = localStorage.getItem(localStorageKey);
  try {
    return JSON.parse(data);
  } catch {
    return undefined;
  }
};

const updateLocalStorage = (key, payload) => {
  let data = getLocalStorage();
  if (!data) data = {};
  localStorage.setItem(localStorageKey, JSON.stringify({ ...data, [key]: payload }));
};

const getLocalStorageAuth = () => {
  const data = getLocalStorage();
  if (data) return data?.auth;
  return undefined;
};

const getLocalStorageByKey = (key) => {
  const data = getLocalStorage();
  if (data) return data?.[key];
  return undefined;
};

// const updateLocalStorageByKey = (key, payload) => {
//   let data = getLocalStorage();
//   if (!data) data = {};
//   updateLocalStorage(key, payload);
// };

function getGiikiInfo() {
  return getSessionStorageByKey(keyInfo);
}

function getCurrentCampus() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.currentCampus];
}

function getNavbarName() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbarName];
}

function getHeaderName() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.headerName];
}
function updateGiikiInfo(field, payload) {
  let info = getGiikiInfo();
  if (!info) info = {};
  info[field] = { ...info[field], ...payload };
  updateSessionStorage(keyInfo, info);
}

function getRecentCampuses() {
  return getLocalStorageByKey(keyRecentCampuses);
}

function updateRecentCampuses(payload) {
  updateLocalStorage(keyRecentCampuses, payload);
}

function getCurrentRoutes() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.routes;
}

function getTitle() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.title;
}

function getCurrentCampusOrCompany() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.currentCampusOrCompany;
}

function getCampusRecent() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.campusRecent;
}

function getCompany() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.company;
}

function getIsCentral() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.isCentral;
}

function getCentralInfo() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.centralInfo;
}

function getIsFinance() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.isFinance;
}

function getIsGiikiAdmin() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.isGiikiAdmin;
}

function getCurrentAcademicYearName() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.currentAcademicYearName;
}

function getPreviousAcademicYearName() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.previousAcademicYearName;
}

function getListAcademicYearName() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.navbar]?.listAcademicYearName;
}

function getMenu() {
  const info = getGiikiInfo();
  if (!info) return undefined;
  return info?.[objFieldGiikiInfo.menu];
}

export {
  giikiAdminId,
  rowsPerPageCount,
  localStorageKey,
  sessionStorageKey,
  objFieldGiikiInfo,
  keyRecentCampuses,
  getLocalStorage,
  getLocalStorageAuth,
  updateLocalStorage as setLocalStorage,
  getSessionStorage,
  getSessionStorageAuth,
  updateSessionStorage as setSessionStorage,
  getLocalStorageByKey,
  getSessionStorageByKey,
  getCurrentCampus,
  getHeaderName,
  getNavbarName,
  updateGiikiInfo,
  getRecentCampuses,
  updateRecentCampuses,
  getCurrentRoutes,
  getTitle,
  getCurrentCampusOrCompany,
  getCampusRecent,
  getCompany,
  getIsCentral,
  getCentralInfo,
  getIsFinance,
  getCurrentAcademicYearName,
  getPreviousAcademicYearName,
  getListAcademicYearName,
  getIsGiikiAdmin,
  getMenu,
};
