import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDebounce from '@hooks/useDebounce';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
} from '@utils/check-error/api-error.js';
import { ToastMessageContext } from 'context/toast-context.jsx';
import BillSection from './components/bill/BillSection.jsx';
import BillSideBar from './components/bill/BillSideBar.jsx';
import PaymentMethodSection from './components/payment-method/PaymentMethodSection.jsx';
import PaymentMethodSideBar from './components/payment-method/PaymentMethodSideBar.jsx';
import CashierPaymentSection from './components/payment/CashierPaymentSection.jsx';
import PaymentSideBar from './components/payment/PaymentSideBar.jsx';
import clsx from 'clsx';
import admissionApi from 'api/admission.js';
import financeApi from 'api/finance.js';
import useStorage from 'store/storage.js';

const steps = ['search', 'bill', 'payment'];

function CashierPayment(props) {
  const { burger } = props
  const [step, setStep] = useState(steps[0]);
  const [user, setUser] = useState({});
  const [applicationId, setApplicationId] = useState('');
  const [fee, setFee] = useState([]);
  const [listScholarships, setListScholarships] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [listEnquiry, setListEnquiry] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [textSearch, setTextSearch] = useState('');
  const debouncedSearch = useDebounce(textSearch, 700);
  const location = useLocation();
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  function checkIsThisMonth(date) {
    if (!date) return true;
    return new Date().getMonth() === new Date(date).getMonth();
  }
  useEffect(() => {
    location.state?.name &&
      setUser({
        avatar: location.state?.avatar,
        name: location.state?.name,
      });
    location.state?.applicationId && setApplicationId(location.state?.applicationId);
    location.state?.applicationId && setStep(steps[1]);
  }, [location.state]);

  useEffect(() => {
    if (applicationId) {
      // const feeApplyRes = callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/fee-payment/payment-information/${applicationId}`,
      //   params: {
      //     campusId: campus?.isCentral ? undefined : campus?.id,
      //     // academicYearName: location.state?.academicYearName,
      //     // programId: location.state?.programId,
      //     // programGradeId: location.state?.programGradeId,
      //   },
      // });
      // const scholarshipRes = callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship/list`,
      //   params: { campusId: campus?.isCentral ? undefined : campus?.id, applicationId: applicationId },
      // });

      // const feeApplyRes = admissionApi.getPaymentInfo({ campusId: campus?.isCentral ? undefined : campus?.id }, applicationId)
      // const scholarshipRes = financeApi.getListScholarship1({ campusId: campus?.isCentral ? undefined : campus?.id, applicationId: applicationId });
      const feeApplyRes = admissionApi.getPaymentInfo({ campusId: campus?.id }, applicationId)
      const scholarshipRes = financeApi.getListScholarship1({ campusId: campus?.id, applicationId: applicationId });
      Promise.all([feeApplyRes, scholarshipRes])
        .then((res) => {
          if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
            let scholarshipsInfo = {};
            if (res[0]?.data?.data) {
              const listFees = [];
              const feeString = res[0].data?.data?.fees;
              if (feeString && typeof feeString === 'string') {
                const feeInfo = JSON.parse(feeString)?.fees || {};
                scholarshipsInfo = JSON.parse(feeString)?.scholarships || {};
                Object.keys(feeInfo).forEach((fee) => {
                  listFees.push({
                    name: fee,
                    id: res[0]?.data?.data?.id,
                    price: +feeInfo[fee]?.fee || 0,
                    date: feeInfo[fee]?.dueFee || new Date(),
                    isSelected:
                      checkIsThisMonth(feeInfo[fee]?.dueFee) && !feeInfo[fee]?.isPaid,
                    isPaid: feeInfo[fee]?.isPaid || false,
                    isNow: checkIsThisMonth(feeInfo[fee]?.dueFee) && !feeInfo[fee]?.isPaid,
                    razorpayBankAccountId: feeInfo[fee]?.razorpayBankAccountId
                  });
                });
              }
              listFees.length > 0 && setFee(listFees);
            }
            const scholarships = (res[1]?.data?.data || []).map((dt) => {
              const info = dt.info;
              let price = 0;
              if (info && typeof info === 'string') {
                price = +JSON.parse(info).total || 0;
              }
              return {
                title: dt.scholarship?.name || 'Scholarship',
                id: dt.id,
                approvedDate: new Date(),
                price: price,
                approverName: !dt.requestId ? [] : dt.approver || [],
                isApproved: !dt.requestId ? true : dt.isApproved,
                isUsed: !!scholarshipsInfo[dt.id]?.isUsed,
              };
            });
            scholarships.length > 0 && setListScholarships(scholarships);
          }
        })
        .catch((err) => {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: err,
          });
          setIsShowToastMessage(true);
        });
    }
  }, [applicationId]);

  const fetchListEnquiry = useCallback(
    async ({ textSearch, currentPage, pageSize, campusId, signal }) => {
      setIsLoading(true);
      try {
        // const api = callApi({
        //   method: 'get',
        //   // url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/search`,
        //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/search`,
        //   params: {
        //     textSearch,
        //     page: currentPage,
        //     pageSize,
        //     campusId,
        //   },
        //   signal,
        // });
        const api = admissionApi.getListEnquirySearch({ textSearch, page: currentPage, pageSize, campusId }, signal)
        const res = await api;
        if (
          checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get enquiries')
        ) {
          setTotalEnquiry(res.data.data.total);
          setListEnquiry(res.data.data.objects);

          if (!res.data.data.total) {
            setToastMessage({
              status: 'info',
              title: 'Enquiry not found',
              message: 'Enquiry not found. Please try again',
            });
            setIsShowToastMessage(true);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.log('Get enquiries error', error);
        setToastMessage({
          status: 'error',
          title: 'Get Enquiries Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        setIsLoading(false);
      }
    },
    [],
  );

  // run the first time mount component
  useEffect(() => {
    const controller = new AbortController();
    !applicationId &&
      fetchListEnquiry({
        textSearch: textSearch,
        currentPage: currentPage,
        pageSize: pageSize,
        // campusId: campus?.isCentral ? undefined : campus?.id,
        campusId: campus?.id,
        signal: controller.signal,
      });

    return () => {
      controller.abort();
    };
  }, [campus]);

  // run every time change page number
  useEffect(() => {
    const controller = new AbortController();
    !applicationId &&
      !isLoading &&
      fetchListEnquiry({
        textSearch: textSearch,
        currentPage: currentPage,
        pageSize: pageSize,
        // campusId: campus?.isCentral ? undefined : campus?.id,
        campusId: campus?.id,
        signal: controller.signal,
      });
    return () => {
      controller.abort();
    };
  }, [currentPage]);

  // run when search
  useEffect(() => {
    setCurrentPage(0);
    const controller = new AbortController();
    !applicationId &&
      !isLoading &&
      fetchListEnquiry({
        textSearch: debouncedSearch,
        currentPage: 0,
        pageSize: pageSize,
        // campusId: campus?.isCentral ? undefined : campus?.id,
        campusId: campus?.id,
        signal: controller.signal,
      });
    return () => {
      controller.abort();
    };
  }, [debouncedSearch]);

  const handleChangePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);
  const handleSetTextSearch = useCallback((value) => {
    setTextSearch(value);
  }, []);
  const handleClickPay = () => {
    setStep(steps[2]);
  };

  const handleSelecttedApplication = (user, applicationId) => {
    setUser(user);
    // setFee(fee);
    // setListScholarships(listScholarships);
    setApplicationId(applicationId);
    setStep(steps[1]);
  };

  const handleClickCancel = () => {
    setStep(steps[0]);
    setApplicationId('');
    setFee([]);
    setListScholarships([]);
  };
  const handlClickBack = () => {
    setStep(steps[1]);
  };
  const handleUpdateSuccess = () => {
    setStep(steps[0]);
  };
  const handleSelected = (idx) => {
    setFee(prev => {
      const data = [...prev];
      data[idx].isSelected = !data[idx].isSelected;
      return data;
    });
  }

  return (
    <div className={clsx('pr-28 pl-0 transition-all-300', burger ? '1400px:pl-[15rem]' : '1400px:pl-[4.5rem]')}>
      {step === steps[0] && (
        <div className="flex min-h-[100vh]">
          <PaymentSideBar
            handleSelecttedApplication={handleSelecttedApplication}
            listEnquiry={listEnquiry}
            totalEnquiry={totalEnquiry}
            pageSize={pageSize}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
            isLoading={isLoading}
            handleSetTextSearch={handleSetTextSearch}
            textSearch={textSearch}
          />
          <CashierPaymentSection />
        </div>
      )}

      {step === steps[1] && (
        <div className="flex bg-[#F9FAFB] min-h-[100vh]">
          <BillSideBar
            applicationId={applicationId}
            user={user}
            fee={fee}
            listScholarships={listScholarships}
            handleClickCancel={handleClickCancel}
            handleSelected={handleSelected}
          />
          <div className="w-2/5 mx-auto">
            <BillSection
              handleClickPay={handleClickPay}
              fee={fee}
              listScholarships={listScholarships}
            />
          </div>
        </div>
      )}

      {step === steps[2] && (
        <div className="flex bg-[#F9FAFB] min-h-[100vh]">
          <PaymentMethodSideBar
            handleClickCancel={handleClickCancel}
            handlClickBack={handlClickBack}
            applicationId={applicationId}
            user={user}
            fee={fee}
            listScholarships={listScholarships}
          />
          <div className="w-2/5 mx-auto">
            <PaymentMethodSection
              applicationId={applicationId}
              fee={fee}
              listScholarships={listScholarships}
              handleUpdateSuccess={handleUpdateSuccess}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CashierPayment;
