import { useHistory, useLocation } from 'react-router-dom';
import { getMessagingToken } from './firebase';
import firebase from './firebase';
import { ToastMessageContext } from '../../../../context/toast-context';
import { useContext } from 'react';
import { USER_ROLE } from '@utils/utils';
import { COMPANY_GIIKI_ID } from '@constant/index';
import { urls } from 'entities/urls';
import authApi from 'api/auth';
import useStorage from 'store/storage';

function getErrorMessage(errorCode) {
  if (!errorCode) return '';
  if (['auth/too-many-requests'].includes(errorCode)) {
    return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
  }
  if (['auth/user-not-found'].includes(errorCode)) {
    return 'Email does not exits. Please check the information!';
  }
  if (
    [
      'auth/wrong-password',
      'auth/invalid-password',
      'auth/invalid-password-hash',
      'auth/invalid-password-salt',
      'auth/invalid-email',
      'auth/invalid-email-verified',
    ].includes(errorCode)
  ) {
    return 'Invalid email or password. Please try again!';
  }
  if (['auth/invalid-phone-number'].includes(errorCode)) {
    return 'Invalid phone number. Please try again!';
  }

  return '';
}

export const useAuth = (tenantId) => {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const history = useHistory();

  // const dispatch = useDispatch();
  const {
    removeAuth,
    updateAuth,
    resetStorage,
    getDeviceToken,
    getAccessToken,
    updateDeviceToken,
    updateAccessToken,
    updateRefreshToken,
    updateIsGiikiAdmin,
  } = useStorage();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  function notifyError(error) {
    const message = getErrorMessage(error.code);
    setToastMessage({
      status: 'error',
      title: 'Login Failed',
      message: message || error,
    });
    setIsShowToastMessage(true);
  }

  function checkRole(accessToken) {
    // const req = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/get-role`,
    //   accessToken: accessToken,
    // });
    const req = authApi.getRole(accessToken);
    const res = req
      .then((response) => {
        const data = response.data.data;
        let isValid = false;
        const roles = data.roles;
        if (roles) {
          isValid = true;
          // if (
          //   roles.some(
          //     (e) => e.loginTypeId.toString() === searchParams.get('loginTypeId'),
          //   )
          // ) {
          //   isValid = true;
          // }
        }
        return {
          isValid: isValid,
          roles: roles,
        };
      })
      .catch((error) => {
        console.log('User Login Error', error);
        return {
          isValid: false,
          roles: [],
        };
      });
    return res;
  }

  const getUserRole = (roles) => {
    if (roles.length === 0) return '';
    for (let role of roles) {
      if (role.companyId === COMPANY_GIIKI_ID && !role.isDefault)
        return USER_ROLE.GIIKI_ADMIN;
      if (role.name === USER_ROLE.PARENT) return USER_ROLE.PARENT;
      if (role.name === USER_ROLE.CENTRAL_ADMIN) return USER_ROLE.CENTRAL_ADMIN;
    }
  };

  function getUserInfo(accessToken) {
    // const req = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/me`,
    //   accessToken: accessToken,
    // });
    const req = authApi.getMe(accessToken);
    const res = req
      .then((response) => {
        const data = response.data.data;
        return {
          ...data,
        };
      })
      .catch((error) => {
        console.log('User Login Error', error);
        return {
          companyId: null,
          campusId: null,
        };
      });
    return res;
  }

  async function handlerCheckRoleAndGetUserInfo(resultSignIn, accessToken, rememberMe) {
    // debugger;
    // const checkedRole = await checkRole(accessToken);
    const userInfoInDB = await getUserInfo(accessToken);
    if (userInfoInDB?.roles?.length) {
      const user = {
        ...resultSignIn.user.multiFactor.user,
        roles: userInfoInDB.roles,
        companyId: userInfoInDB.companyId,
        campusId: userInfoInDB.campusId,
        status: userInfoInDB.status,
        photoURL: userInfoInDB.photoURL,
      };
      await handleUser(user, rememberMe);
      const userRole = getUserRole(userInfoInDB.roles);
      switch (userRole) {
        case USER_ROLE.PARENT:
          history.push('/bloomingdale-welcome');
          history.go('/bloomingdale-welcome');
          break;
        // case USER_ROLE.CENTRAL_ADMIN:
        //   history.push(urls.home);
        //   history.go(urls.home);
        //   break;
        case USER_ROLE.GIIKI_ADMIN:
          updateIsGiikiAdmin(true);
          history.push(urls.giiki.dashboard);
          history.go(urls.giiki.dashboard);
          break;
        default:
          history.push(urls.home);
          history.go(urls.home);
          break;
      }

      // if (checkedRole.roles.some((e) => e.name === 'Parent')) {
      //   history.push('/bloomingdale-welcome');
      //   history.go('/bloomingdale-welcome');
      // } else if (checkedRole.roles.some((e) => e.name === 'Central Admin')) {
      //   history.push('/home');
      //   history.go('/home');
      // } else {
      //   history.push('/home');
      //   history.go('/home');
      // }
    } else {
      handleUser(null, rememberMe);
      // dispatch(deleteAuth());
      removeAuth();
      setToastMessage({
        status: 'error',
        title: 'Login Failed',
        message: 'You do not have permission',
      });
      setIsShowToastMessage(true);
      if (userInfoInDB.roles.some((e) => e.name === 'Parent')) {
        history.push('/bloomingdale-login');
      } else {
        history.push('/login/type');
      }
    }
  }

  function syncPhotoUrl(accessToken, photoURL) {
    // const req = callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/sync-photo`,
    //   data: {
    //     photoURL: photoURL,
    //   },
    //   accessToken: accessToken,
    // });
    const req = authApi.syncPhoto({ photoURL }, accessToken);
    return req;
  }

  const syncDeviceToken = (accessToken, deviceToken) => {
    // const result = callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/sync-device-token`,
    //   data: {
    //     deviceToken: deviceToken,
    //   },
    //   accessToken: accessToken,
    // });
    const result = authApi.syncDeviceToken({ deviceToken }, accessToken);
    return result;
  };

  const handleUser = async (user, rememberMe) => {
    console.log('handleUser', user);
    if (user) {
      const deviceToken = await getMessagingToken(setToastMessage, setIsShowToastMessage);
      // user['deviceToken'] = deviceToken;
      updateDeviceToken(deviceToken);

      if (deviceToken !== 'turnoff' && deviceToken) {
        await syncDeviceToken(user.accessToken, deviceToken);
      }

      // if (rememberMe === true) {
      //   localStorage.setItem('giiki-web', JSON.stringify(user));
      // } else if (rememberMe === false) {
      //   sessionStorage.setItem('giiki-web', JSON.stringify(user));
      // }
      if (!user.photoURL) {
        syncPhotoUrl(user.accessToken, user.photoURL);
      }
      // dispatch(saveAuth(user));
      updateAuth(user);
      updateAccessToken(user.accessToken);
      updateRefreshToken(user.auth?.currentUser?.stsTokenManager?.refreshToken ?? null);
    }
  };

  function signInWithGoogle(rememberMe) {
    firebase.auth().tenantId = tenantId;
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (result) => {
        const accessToken = result.user.auth.currentUser.accessToken;
        handlerCheckRoleAndGetUserInfo(result, accessToken, rememberMe);
      })
      .catch((error) => {
        if ((error.code = 'auth/popup-closed-by-user')) return;
        notifyError(error);
        history.push('/login/user');
      });
  }

  const signInWithMicrosoft = (rememberMe) => {
    firebase.auth().tenantId = tenantId;
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'))
      .then(async (result) => {
        const accessToken = result.user.auth.currentUser.accessToken;
        handlerCheckRoleAndGetUserInfo(result, accessToken, rememberMe);
      })
      .catch((error) => {
        if ((error.code = 'auth/popup-closed-by-user')) return;
        notifyError(error);
        history.push('/login/user');
      });
  };

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(async () => {
        // const userInfo = JSON.parse(
        //   sessionStorage.getItem('giiki-web') || localStorage.getItem('giiki-web'),
        // );
        // const deviceToken = userInfo?.deviceToken;
        // const accessToken = userInfo?.accessToken;
        const deviceToken = getDeviceToken();
        const accessToken = getAccessToken();

        if (deviceToken !== 'turnoff' && deviceToken) {
          // await callApi({
          //   method: 'patch',
          //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/delete-device-token`,
          //   data: {
          //     deviceToken: deviceToken,
          //   },
          //   accessToken: accessToken,
          // });
          await authApi.deleteDeviceToken({ deviceToken }, accessToken);
        }

        // sessionStorage.removeItem('giiki-web');
        // sessionStorage.removeItem(keyInfo);
        // localStorage.removeItem('giiki-web');
        // dispatch(deleteAuth());
        // removeAuth();
        resetStorage();
        history.push('/');
      });
  };

  const logInWithEmailAndPassword = (email, password, rememberMe) => {
    firebase.auth().tenantId = tenantId;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        const accessToken = result.user.auth.currentUser.accessToken;
        handlerCheckRoleAndGetUserInfo(result, accessToken, rememberMe);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  return {
    notifyError,
    logInWithEmailAndPassword,
    signInWithGoogle,
    signInWithMicrosoft,
    signOut,
    handleUser,
    checkRole,
  };
};
