const companyStatus = ['Currently Active', 'Inactive', 'Upcoming Billings'];

const listDataCategoryRole = [
  {
    name: 'Admin',
    label: 'Admin',
    value: 1,
  },
  {
    name: 'Parent',
    label: 'Parent',
    value: 2,
  },
  {
    name: 'Student',
    label: 'Student',
    value: 3,
  },
  {
    name: 'Teacher/Staff',
    label: 'Teacher/Staff',
    value: 4,
  },
];

const BusinessTypes = [
  {
    value: 'private_limited',
    label: 'Private Limited',
  },
  {
    value: 'society',
    label: 'Society',
  },
];

const PartnerType = {
  School: 'School',
  Company: 'Company',
};
const optionFee = [
  { label: 'Zero', value: 'Zero' },
  { label: 'Same', value: 'Same' },
  { label: 'Different', value: 'Different' },
];
const listTutionFeeKey = [
  'admissionFee',
  'applicationFee',
  'bookKitFee',
  'estAnnualFee',
  'feeTypeFee',
];
const listResidentialFeeKey = ['feeTypeFee', 'estAnnualFee', 'approxTermFee'];

const eventTypes = [
  { value: 'School Tour', label: 'School Tour' },
  // { value: 'Meeting With Principal', label: 'Meeting With Principal' },
];

const objEventType = {
  'School Tour': 'School Tour',
  // 'Meeting With Principal': 'Meeting With Principal',
};

const objRole = {
  Principal: 'Principal',
};

const APPOINTMENT_STATUS = {
  Confirmed: 'Confirmed',
  Canceled: 'Canceled',
  'Call to confirm': 'Call to confirm',
};

const initScholarship = {
  scholarshipId: null,
  documents: [],
  urlDocuments: [],
  info: {
    comment: '',
    fees: [],
  },
};
const defaultAvatar =
  'https://storage.googleapis.com/giiki-bucket/media/$2b$10$14MgpBubl1aenO6steQm7OuYM6jVXhkpzSHzRA71ZEc9iY3XQAXPe';
export {
  PartnerType,
  optionFee,
  listTutionFeeKey,
  listResidentialFeeKey,
  BusinessTypes,
  eventTypes,
  objEventType,
  objRole,
  APPOINTMENT_STATUS,
  listDataCategoryRole,
  companyStatus,
  initScholarship,
  defaultAvatar,
};
