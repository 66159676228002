import {
  campusAdmissionPath,
  campusFinancePath,
  campusSettingPath,
  centralPath,
} from '@utils/utils';
import {
  dashboard,
  bshouse,
  bspiechart,
  icuser,
  verified,
  icupload,
  iccopy,
  authenticator,
  bsgrid1x2,
  bspeople,
  bsaward,
  bscreditcard,
  bsgear,
  ic_dashboard,
  ic_edit,
  ic_location,
  ic_setting,
  ic_person,
  ic_profile,
  ic_user,
  ic_user_group,
  ic_verified,
  ic_vpn_key,
  ic_award,
  ic_creditcard,
  ic_grid1x2,
  ic_people,
  ic_piechart,
  f_ic_blog,
  f_ic_calendar,
  f_ic_carousel,
  f_ic_cart,
  f_ic_components,
  f_ic_copy,
  f_ic_dashboard,
  f_ic_editor,
  f_ic_elements,
  f_ic_language,
  f_ic_page,
  f_ic_upload,
  f_ic_user,
  central_document,
  central_form,
  central_campuses,
  central_user,
  central_role,
  central_feebook,
  central_scholarship,
  central_cashier_screen,
  central_cashier_report,
  central_bank_account,
  central_commu_template,
  central_notification,
  central_whatsapp,
  central_phone,
  campus_enquiries,
  campus_enquiries_overview,
  campus_cashier_payment,
  campus_setting,
  campus_academic_year,
  campus_program,
} from '../static/image';
import { urls } from './urls';

// export const routes = [
//   {
//     type: 'span',
//     name: 'GENERAL',
//   },
//   {
//     path: '/dashboard',
//     icon: dashboard,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   {
//     path: '/login',
//     icon: authenticator,
//     type: 'link',
//     name: 'Login',
//     multiPath: [
//       {
//         path: '/login/type',
//         name: 'Type User',
//       },
//       {
//         path: '/login/forgot',
//         name: 'Forgot Password',
//       },
//       {
//         path: '/login/otp',
//         name: 'OTP Phone',
//       },
//       // {
//       //   path: '/login/change-password',
//       //   name: 'Change Password'
//       // }
//     ],
//   },
//   {
//     path: '/tasks',
//     icon: iccopy,
//     type: 'link',
//     name: 'Tasks',
//   },
//   {
//     path: '/overview',
//     icon: icuser,
//     type: 'link',
//     name: 'Admissional Overview',
//   },
//   {
//     path: '/enquiry/new',
//     icon: icupload,
//     type: 'link',
//     name: 'New Enquiry',
//   },
//   {
//     path: '/application/application',
//     icon: verified,
//     type: 'link',
//     name: 'Application',
//   },
//   {
//     path: '/campus',
//     icon: bshouse,
//     type: 'link',
//     name: 'School Setup',
//     multiPath: [
//       {
//         path: '/campus/academic',
//         name: 'Academic Years',
//       },
//       {
//         path: '/campus/classes',
//         name: 'Grades & Programs',
//       },
//     ],
//   },
//   {
//     type: 'span',
//     name: 'FEE MANAGEMENT',
//   },
//   {
//     path: '/finance',
//     icon: bspiechart,
//     type: 'link',
//     name: 'Finance Module',
//     multiPath: [
//       {
//         path: '/finance/feebooks',
//         name: 'FeeBooks',
//       },
//       {
//         path: '/finance/scholarship',
//         name: 'Scholarship',
//       },
//     ],
//   },
// ];

export const giikiAdminRoutes = [
  {
    type: 'span',
    name: 'GENERAL',
  },
  // {
  //   path: urls.giiki.home,
  //   icon: icuser,
  //   type: 'link',
  //   name: 'Home',
  // },
  {
    path: urls.giiki.dashboard,
    icon: icuser,
    type: 'link',
    name: 'Dashboard',
  },
  {
    path: urls.giiki.institutions.base,
    icon: iccopy,
    type: 'link',
    name: 'Institutions',
  },
  {
    type: 'span',
    name: 'USER MANAGEMENT',
  },
  {
    path: urls.giiki.users.base,
    icon: iccopy,
    type: 'link',
    name: 'Members',
  },
  {
    path: urls.giiki.roles,
    icon: iccopy,
    type: 'link',
    name: 'Roles and Permissions',
  },
  {
    type: 'span',
    name: 'OTHERS',
  },
  {
    path: urls.giiki.report.user,
    icon: iccopy,
    type: 'link',
    name: 'Reports',
  },
  {
    path: urls.giiki.setting,
    icon: iccopy,
    type: 'link',
    name: 'Settings',
  },
];

// export const admissionRoutes = [
//   {
//     type: 'span',
//     name: 'ADMISSION MODULE',
//   },
//   {
//     path: '/dashboard',
//     icon: ic_dashboard,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   {
//     path: '/home',
//     icon: bshouse,
//     type: 'link',
//     name: 'Home',
//     multiPath: [
//       {
//         path: '/admission/stages',
//         name: 'Stages',
//       },
//       {
//         path: '/settings/tasks-settings',
//         name: 'Tasks and Reminders',
//       },
//       {
//         path: '/fields',
//         name: 'Fields',
//       },
//       // {
//       //   path: '/setting',
//       //   name: 'Settings',
//       // },
//     ],
//   },
//   {
//     path: '/admission/setting/team',
//     icon: ic_setting,
//     type: 'link',
//     name: 'Setting',
//     multiPath: [
//       {
//         path: '/notification',
//         name: 'Notifications',
//       },
//       {
//         path: '/admission/setting/team',
//         name: 'Team Settings',
//       },
//     ],
//   },
//   {
//     type: 'span',
//     name: 'STUDENTS',
//   },
//   {
//     path: '/application/application',
//     icon: ic_edit,
//     type: 'link',
//     name: 'Enquiries',
//   },
//   {
//     path: '/students',
//     icon: ic_user,
//     type: 'link',
//     name: 'Students',
//   },
//   {
//     path: '/families',
//     icon: ic_user_group,
//     type: 'link',
//     name: 'Families',
//   },
//   {
//     type: 'span',
//     name: 'FINANCE',
//   },
//   {
//     path: '/payments',
//     icon: ic_vpn_key,
//     type: 'link',
//     name: 'Payments',
//   },
//   {
//     type: 'span',
//     name: 'ANALYTICS',
//   },
//   {
//     path: '/enquiry/overview',
//     icon: ic_person,
//     type: 'link',
//     name: 'Enquiry',
//   },
//   {
//     path: '/funnel',
//     icon: ic_verified,
//     type: 'link',
//     name: 'Conversion Funnel',
//   },
//   {
//     path: '/geography',
//     icon: ic_location,
//     type: 'link',
//     name: 'Geography',
//   },
//   {
//     path: '/nationality',
//     icon: ic_profile,
//     type: 'link',
//     name: 'Nationality',
//   },
//   {
//     path: '/custom',
//     icon: ic_setting,
//     type: 'link',
//     name: 'Custom',
//   },
// ];

// export const financeRoutes = [
//   {
//     type: 'span',
//     name: 'General',
//   },
//   {
//     path: '/finance/dashboard',
//     icon: ic_dashboard,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   {
//     type: 'span',
//     name: 'Fee Management',
//   },
//   {
//     path: '/finance/feebooks',
//     icon: f_ic_user,
//     type: 'link',
//     name: 'Fee Books',
//   },
//   {
//     path: '/finance/fee-due',
//     icon: f_ic_cart,
//     type: 'link',
//     name: 'Fee Due',
//   },
//   {
//     path: '/finance/scholarship',
//     icon: f_ic_blog,
//     type: 'link',
//     name: 'Scholarship ',
//   },
//   {
//     path: '/finance/finance-calendar',
//     icon: f_ic_calendar,
//     type: 'link',
//     name: 'Finance Calendar',
//   },
//   {
//     type: 'span',
//     name: 'Budgeting',
//   },
//   {
//     path: '/finance/budget ',
//     icon: f_ic_editor,
//     type: 'link',
//     name: 'Budget ',
//   },
//   {
//     path: '/finance/budget-approvals',
//     icon: f_ic_elements,
//     type: 'link',
//     name: 'Budget Approvals',
//   },
//   {
//     path: '/finance/payment-approvals',
//     icon: f_ic_components,
//     type: 'link',
//     name: 'Payment Approvals',
//   },

//   {
//     type: 'span',
//     name: 'Reports',
//   },
//   {
//     path: '/finance/fee-reports ',
//     icon: f_ic_copy,
//     type: 'link',
//     name: 'Fee Reports ',
//   },
//   {
//     path: '/finance/budget-reports',
//     icon: f_ic_upload,
//     type: 'link',
//     name: 'Budget Reports',
//   },

//   {
//     type: 'span',
//     name: 'Payments',
//   },
//   {
//     path: '/finance/invoices',
//     icon: f_ic_carousel,
//     type: 'link',
//     name: 'Invoices',
//   },
//   {
//     path: '/finance/multi-language',
//     icon: f_ic_language,
//     type: 'link',
//     name: 'Multi Language',
//   },
//   {
//     path: '/finance/billing',
//     icon: f_ic_page,
//     type: 'link',
//     name: 'Billing',
//   },
// ];

// export const taskManagementRoutes = [
//   {
//     type: 'span',
//     name: 'GENERAL',
//   },
//   {
//     path: '/dashboard',
//     icon: ic_grid1x2,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   {
//     path: '/tasks',
//     icon: bshouse,
//     type: 'link',
//     name: 'Task',
//     multiPath: [
//       {
//         path: '/tasks/all',
//         name: 'All',
//         count: 0,
//         key: 'all',
//       },
//       {
//         path: '/tasks/work',
//         name: 'My Work',
//       },
//       // {
//       //   path: '/tasks/list-delegations',
//       //   name: 'My Delegations',
//       // },

//       {
//         path: '/tasks/processing',
//         name: 'In Progress',
//         count: 0,
//         key: 'In progress',
//       },
//       {
//         path: '/tasks/done',
//         name: 'Done',
//         count: 0,
//         key: 'Done',
//       },
//     ],
//   },
//   {
//     type: 'span',
//     name: 'Quick Links',
//   },
//   {
//     path: '/projects',
//     icon: ic_people,
//     type: 'link',
//     name: 'Projects',
//   },
//   {
//     path: '/time-sheet',
//     icon: ic_people,
//     type: 'link',
//     name: 'Time Sheets',
//   },
//   {
//     path: '/goals',
//     icon: ic_award,
//     type: 'link',
//     name: 'Goals',
//   },
//   {
//     type: 'span',
//     name: 'OTHERS',
//   },
//   {
//     path: '/calendar',
//     icon: ic_creditcard,
//     type: 'link',
//     name: 'Calendar',
//   },
//   {
//     path: '/reports',
//     icon: ic_piechart,
//     type: 'link',
//     name: 'Reports',
//   },
//   {
//     path: '/settings',
//     icon: bsgear,
//     type: 'link',
//     name: 'Settings',
//   },
// ];

// export const centralAdminRoutes = [
//   {
//     type: 'span',
//     name: 'GENERAL',
//   },
//   {
//     path: '/home',
//     icon: bshouse,
//     type: 'link',
//     name: 'Home',
//   },
//   {
//     path: '/central-admin/dashboard',
//     icon: bsgrid1x2,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   {
//     path: '/central-admin/campuses',
//     icon: bshouse,
//     type: 'link',
//     name: 'Campuses',
//     // multiPath: [
//     //   {
//     //     path: '/central-admin/campuses/general',
//     //     name: 'General',
//     //   },
//     //   {
//     //     path: '/central-admin/campuses/grades-programs',
//     //     name: 'Grades & Programs',
//     //   },
//     //   {
//     //     path: '/central-admin/campuses/roles-permissions',
//     //     name: 'Roles & Permissions',
//     //   },
//     //   {
//     //     path: '/central-admin/campuses/members',
//     //     name: 'Members',
//     //   },
//     // ],
//   },
//   {
//     type: 'span',
//     name: 'USER MANAGEMENT',
//   },
//   {
//     path: '/central-admin/user-management/users',
//     icon: bspeople,
//     type: 'link',
//     name: 'Users',
//   },
//   {
//     path: '/central-admin/user-management/roles',
//     icon: bsaward,
//     type: 'link',
//     name: 'Roles',
//   },
//   {
//     type: 'span',
//     name: 'OTHERS',
//   },
//   {
//     path: '/central-admin/bank-account',
//     icon: bscreditcard,
//     type: 'link',
//     name: 'Billing',
//   },
//   {
//     path: '/central-admin/reports',
//     icon: bspiechart,
//     type: 'link',
//     name: 'Reports',
//   },
//   {
//     path: '/central-admin/settings',
//     icon: bsgear,
//     type: 'link',
//     name: 'Settings',
//     multiPath: [
//       {
//         path: '/central-admin/settings/whatsapp-credentials',
//         name: 'Whatsapp Credentials',
//       },
//     ],
//   },
// ];

// export const adminRoutes = [
//   {
//     type: 'span',
//     name: 'GENERAL',
//   },
//   {
//     path: '/notification',
//     icon: icuser,
//     type: 'link',
//     name: 'Notification',
//   },
//   {
//     path: '/institutions',
//     icon: iccopy,
//     type: 'link',
//     name: 'Institutions',
//   },
//   {
//     path: '/admission/stages',
//     icon: bspiechart,
//     type: 'link',
//     name: 'Admission Stages',
//   },
//   {
//     path: '/managment',
//     icon: dashboard,
//     type: 'link',
//     name: 'Custom Fields',
//   },
//   {
//     path: '/settings/user-settings',
//     icon: authenticator,
//     type: 'link',
//     name: 'Settings',
//   },
//   {
//     path: '/settings/tasks-settings',
//     icon: iccopy,
//     type: 'link',
//     name: 'Tasks and Reminders',
//   },
//   {
//     path: '/giiki',
//     icon: bshouse,
//     type: 'link',
//     name: 'Company',
//     multiPath: [
//       {
//         path: '/giiki/company',
//         name: 'Company Management',
//       },
//       {
//         path: '/giiki/company/settings',
//         name: 'Company Settings',
//       },
//     ],
//   },
//   {
//     path: '/worlds',
//     icon: verified,
//     type: 'link',
//     name: 'Worlds',
//     multiPath: [
//       {
//         path: '/worlds/giiki',
//         name: 'Giiki World',
//       },
//       {
//         path: '/worlds/admissions',
//         name: 'Admissions World',
//       },
//     ],
//   },
//   {
//     path: '/templates',
//     icon: icupload,
//     type: 'link',
//     name: 'Template',
//     multiPath: [
//       {
//         path: '/templates/',
//         name: 'Templates',
//       },

//       {
//         path: '/templates/edit-template',
//         name: 'Edit Template',
//       },
//     ],
//   },
//   {
//     // path: '/detail',
//     path: '/central-admin/campuses',
//     icon: icupload,
//     type: 'link',
//     name: 'Campus',
//     multiPath: [
//       {
//         path: '/detail/company',
//         name: 'Campus Details',
//       },
//       {
//         path: '/detail/company-edit',
//         name: 'Campus Edit',
//       },
//       {
//         path: '/central-admin/campuses',
//         name: 'Campus List',
//       },
//       {
//         path: '/detail/campus-information',
//         name: 'Campus Information',
//       },
//       {
//         path: '/detail/user-list',
//         name: 'User List',
//       },
//       {
//         path: '/detail/approvals',
//         name: 'Approvals cards',
//       },
//     ],
//   },
// ];

// export const campusAdminRoutes = [
//   {
//     type: 'span',
//     name: 'GENERAL',
//   },
//   {
//     path: '/dashboard',
//     icon: bsgrid1x2,
//     type: 'link',
//     name: 'Dashboard',
//   },
//   // {
//   //   path: '/notification',
//   //   icon: bsgrid1x2,
//   //   type: 'link',
//   //   name: 'Notifications',
//   // },
//   {
//     path: '/campus/campus-information',
//     icon: bshouse,
//     type: 'link',
//     name: 'School Setup',
//     multiPath: [
//       {
//         path: '/campus/campus-information',
//         name: 'Information',
//       },
//       // {
//       //   path: '/detail/campus-edit',
//       //   name: 'Edit',
//       // },
//       {
//         path: '/central-admin/campus/classes',
//         name: 'Grades & Programs',
//       },
//       // {
//       //   path: '/central-admin/campus/fee-payment',
//       //   name: 'Grades Fee Payment',
//       // },
//       {
//         path: '/central-admin/campus/academic/middle',
//         name: 'Academic Year Terms',
//       },
//       {
//         path: '/admission/setting/team',
//         name: 'Admission Team Settings',
//       },
//       {
//         path: '/admission/setting/document',
//         name: 'Admission Document Settings',
//       },
//       {
//         path: '/campus/distribution',
//         name: 'Distribution',
//       },
//       {
//         path: '/campus/campus-admission/forms',
//         name: 'Forms',
//       },
//       // {
//       //   path: '/settings/tasks-settings',
//       //   name: 'Task Reminder',
//       // },
//       // {
//       //   path: '/central-admin/campus/programmes',
//       //   name: 'Modify School',
//       // },
//     ],
//   },
//   {
//     type: 'span',
//     name: 'USER MANAGEMENT',
//   },
//   {
//     path: '/campus/users',
//     icon: bspeople,
//     type: 'link',
//     name: 'Users',
//   },
//   {
//     path: '/campus/roles',
//     icon: bsaward,
//     type: 'link',
//     name: 'Roles',
//   },
//   {
//     type: 'span',
//     name: 'OTHERS',
//   },
//   {
//     path: '/central-admin/others/billing',
//     icon: bscreditcard,
//     type: 'link',
//     name: 'Billing',
//   },
//   {
//     path: '/central-admin/others/reports',
//     icon: bspiechart,
//     type: 'link',
//     name: 'Reports',
//   },
//   {
//     path: '/settings',
//     icon: bsgear,
//     type: 'multi',
//     name: 'Settings',
//     multiPath: [
//       {
//         path: '/settings/notification',
//         name: 'Notifications',
//       },
//       {
//         path: '/settings/whatsapp-credentials',
//         name: 'Whatsapp Credentials',
//       },
//       {
//         path: '/settings/tasks-settings',
//         name: 'Task Reminder',
//       },
//     ],
//   },
// ];

export const dataTabs = {
  academTabs: [
    {
      name: 'IB Middle Years',
      path: '/central-admin/campus/academic/middle',
      type: '/middle',
    },
    {
      name: 'IB Enhanced Primary Years',
      path: '/central-admin/campus/academic/primary',
      type: '/primary',
    },
    {
      name: 'IB Diploma Programmes',
      path: '/central-admin/campus/academic/programmes',
      type: '/programmes',
    },
  ],
  programTabs: [
    {
      name: 'International Baccalaureate',
      path: '/central-admin/campus/programmes/inter-bacca',
      type: '/inter-bacca',
    },
    { name: 'CBSE', path: '/central-admin/campus/programmes/cbse', type: '/cbse' },
    {
      name: 'IGCSE',
      path: '/central-admin/campus/programmes/igcse',
      type: '/igcse',
    },
  ],
  giikiReportTabs: [
    {
      name: 'User Activity Reports',
      path: '/giiki/reports/user',
      type: '/user',
    },
    { name: 'Bugs Reports', path: '/giiki/reports/bug', type: '/bug' },
    { name: 'Issues Reports', path: '/giiki/reports/issue', type: '/issue' },
  ],
};
export const dataLinks = {
  taskLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Task', path: '/tasks' },
    { name: 'Board Setting', path: '/tasks/team-setting', active: true },
  ],
  academLinks: [
    { name: 'Home', path: '/home' },
    {
      name: 'Academic Year',
      path: '/central-admin/campus/academic/middle',
      active: true,
    },
    // { name: 'Academic Years', path: '/campus/academic', active: true }
  ],
  programLinks: [
    { name: 'Home', path: '/home' },
    {
      name: 'School Setup',
      path: '/central-admin/campus/academic/middle',
      active: true,
    },
    // { name: 'Academic Years', path: '/campus/academic', active: true }
  ],
  gradesLinks: [
    { name: 'Home', path: '/home' },
    { name: 'School Setup', path: '/campus' },
    { name: 'Grades and programs', path: '/campus/grades', active: true },
  ],
  classesLinks: [
    { name: 'Home', path: '/home' },
    { name: 'School Setup', path: '/central-admin/campus/academic/middle' },
    {
      name: 'Grades and Programs',
      path: '/central-admin/campus/classes',
      active: true,
    },
  ],
  feeLinks: [
    { name: 'Home', path: '/home' },
    { name: 'School Setup', path: '/central-admin/campus/academic/middle' },
    {
      name: 'Grades Fee Payment',
      path: '/central-admin/campus/fee-payment',
      active: true,
    },
  ],
  feebooksLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Finance Module', path: '/finance' },
    { name: 'Fee Books', path: '/finance/feebooks', active: true },
  ],
  scholashipsLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Finance Module', path: '/finance' },
    { name: 'Scholarship', path: '/finance/scholarship', active: true },
  ],
  feebooksLinksEdit: [
    { name: 'Home', path: '/home' },
    { name: 'Finance Module', path: '/finance/dashboard' },
    { name: 'Fee Books', path: '/finance/feebooks' },
    { name: 'Fee Book IB Edit', path: '/finance/feebooks/edit', active: true },
  ],
  applicationLinks: [
    { name: 'Home', path: '/home', active: true },
    { name: 'Enquiries', path: '/application/application', active: true },
  ],
  enquiryLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Enquiries', path: '/enquiries' },
    { name: 'Add a Enquiries', path: '/enquiries/new', active: true },
  ],
  institutionsLinks: [
    { name: 'General', path: '/home' },
    {
      name: 'Institutions List',
      path: '/institutions/getting-start',
      active: true,
    },
  ],
  campusDetailLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Campus List', path: '/central-admin/campuses' },
    { name: 'Edits Campus', path: '/detail/campuses-edit', active: true },
  ],
  createUserLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Create User', path: '/create-user', active: true },
  ],
  editUserLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Edit User', path: '/edit-user', active: true },
  ],
  createUserForGiikiLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Create User', path: '/create-user-for-company', active: true },
  ],
  importUserLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Import Center', path: '/import-user', active: true },
  ],
  campusListLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Campus List', path: '/central-admin/campuses', active: true },
  ],
  campusInfoLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Campuses', path: '/central-admin/campuses' },
    {
      name: 'Campus Information',
      path: '/detail/campus-information',
      active: true,
    },
  ],
  campusRoleLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Campuses', path: '/central-admin/campuses' },
    { name: 'Create Role', path: '/company/create-role', active: true },
  ],
  admissionsWorldLinks: [
    { name: 'Admissions Home', path: '/home' },
    {
      name: 'Campus Admission Settings',
      path: '/worlds/admissions',
      active: true,
    },
  ],
  admissionsStagesLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Stages', path: '/stages', active: true },
  ],
  managmentLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Fields', path: '/managment', active: true },
  ],
  notificationsLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Notifications', path: '/notification', active: true },
  ],
  whatsappCredentialsLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Whatsapp Credentials', path: '/whatsapp-credentials', active: true },
  ],
  tasksSettingLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Tasks and Reminders   ', path: '/tasks-settings', active: true },
  ],
  emailLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Email', path: '/settings/user-settings', active: true },
  ],
  editLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Edit Template', path: '/settings/edit-template', active: true },
  ],
  admissionsSettingLinks: [
    { name: 'Admissions Home', path: '/home' },
    { name: 'Settings', path: '/settings', active: true },
  ],
  admissionTeamSettingsLinks: [
    { name: 'Admission Settings', path: '/' },
    { name: 'Admission Team Settings', path: '/admission/setting/team', active: true },
  ],
  admissionDocumentSettingsLinks: [
    { name: 'Admission Settings', path: '/' },
    {
      name: 'Admission Documents Settings   ',
      path: '/admission/setting/document',
      active: true,
    },
  ],
  ApprovalEditLinks: [
    { name: 'Admission Settings', path: '/' },
    { name: 'Admission Approvals   ', path: '/approvals', active: true },
  ],
  communicationEditLinks: [
    { name: 'Admissions', path: '/' },
    { name: 'Communication Template', path: '/detail/templates', active: true },
  ],
  rolePermissionLinks: [
    { name: 'Home', path: '/home' },
    // { name: "Campus List", path: "/central-admin/campuses", active: true },
  ],
  ApprovalRequestsLinks: [
    { name: 'Approvals', path: '/approvals' },
    { name: 'Requests', path: '/approvals', active: true },
  ],
  ApprovalMyRequestsLinks: [
    { name: 'Home', path: '/home' },
    { name: 'My Requests', path: '/approvals/my-requests', active: true },
  ],
  taskDelegationLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Task', path: '/tasks' },
    { name: 'List delegations', path: '/tasks/list-delegations', active: true },
  ],
  formLinks: [
    { name: 'Home', path: '/home' },
    { name: 'Admissions Home', path: '/admission' },
    { name: 'Forms    ', path: '/forms', active: true },
  ],
};

export const campusAdmissionRoutes = [
  {
    path: urls.campus.admission.dashboard,
    icon: dashboard,
    type: 'link',
    name: 'Dashboard',
  },
  {
    path: urls.campus.admission.applications,
    icon: campus_enquiries,
    type: 'link',
    name: 'Enquiries',
  },
  {
    path: urls.campus.admission.enquiriesOverview,
    icon: campus_enquiries_overview,
    type: 'link',
    name: 'Enquiries Overview',
  },
  {
    path: urls.campus.admission.cashierPayments,
    icon: campus_cashier_payment,
    type: 'link',
    name: 'Cashier/Payments',
  },
  {
    icon: campus_setting,
    type: 'multi',
    name: 'Admission Settings',
    paths: [
      urls.campus.admission.setting.distributions,
      urls.campus.admission.setting.teams,
      urls.campus.admission.setting.documents,
      urls.campus.admission.setting.approvals,
      urls.campus.admission.setting.forms,
      urls.campus.admission.setting.fields,
      urls.campus.admission.setting.communicationTemplates,
      urls.campus.admission.setting.workflow,
    ],
    multiPath: [
      {
        path: urls.campus.admission.setting.distributions,
        name: 'Lead Distribution Rule',
      },
      {
        path: urls.campus.admission.setting.teams,
        name: 'Admission Team Settings',
      },
      {
        path: urls.campus.admission.setting.documents,
        name: 'Documents Settings',
      },
      {
        path: urls.campus.admission.setting.approvals,
        name: 'Campus Admissions Approval',
      },
      {
        path: urls.campus.admission.setting.forms,
        name: 'Form Builder',
      },
      {
        path: urls.campus.admission.setting.fields,
        name: 'Fields',
      },
      {
        path: urls.campus.admission.setting.communicationTemplates,
        name: 'Communication Templates',
      },
      {
        path: urls.campus.admission.setting.workflow,
        name: 'Admission Workflow',
      },
    ],
  },
];
export const campusSettingRoutes = [
  {
    path: urls.campus.setting.academicYears,
    icon: campus_academic_year,
    type: 'link',
    name: 'Academic years',
  },
  {
    path: urls.campus.setting.programClasses,
    icon: campus_program,
    type: 'link',
    name: 'Programs and Classes',
  },
  {
    path: urls.campus.setting.users.base,
    icon: central_user,
    type: 'link',
    name: 'Users',
  },
  {
    path: urls.campus.setting.roles,
    icon: central_role,
    type: 'link',
    name: 'Roles',
  },
  {
    path: urls.campus.setting.keyRoles,
    icon: iccopy,
    type: 'link',
    name: 'Key Roles',
  },
];
export const campusFinanceRoutes = [
  {
    path: urls.campus.finance.cashierPayment,
    icon: central_cashier_screen,
    type: 'link',
    name: 'Cashier/Payment',
  },
  {
    path: urls.campus.finance.feebooks,
    icon: bsgear,
    type: 'link',
    name: 'Fee Settings',
  },
  {
    path: urls.campus.finance.scholarships,
    icon: f_ic_blog,
    type: 'link',
    name: 'Scholarship Settings',
  },
];
export const centralAdmissionRoutes = [
  {
    path: urls.central.admission.documentSettings,
    icon: central_document,
    type: 'link',
    name: 'Document Settings',
  },
  {
    path: urls.central.admission.forms,
    icon: central_form,
    type: 'link',
    name: 'Forms',
  },
];
export const centralSetupRoutes = [
  {
    path: urls.central.setup.campuses,
    icon: central_campuses,
    type: 'link',
    name: 'Campuses',
  },
  {
    path: urls.central.setup.users.base,
    icon: central_user,
    type: 'link',
    name: 'Users',
  },
  {
    path: urls.central.setup.roles,
    icon: central_role,
    type: 'link',
    name: 'Roles',
  },
];
export const centralFinanceRoutes = [
  {
    path: urls.central.finance.feebooks,
    icon: central_feebook,
    type: 'link',
    name: 'Feebook Settings',
  },
  {
    path: urls.central.finance.scholarships,
    icon: central_scholarship,
    type: 'link',
    name: 'Scholarship Settings',
  },
  {
    path: urls.central.finance.cashierScreen,
    icon: central_cashier_screen,
    type: 'link',
    name: 'Cashier Screen',
  },
  {
    path: urls.central.finance.cashierReports,
    icon: central_cashier_report,
    type: 'link',
    name: 'Cashier Reports',
  },
  {
    path: urls.central.finance.bankAccounts,
    icon: central_bank_account,
    type: 'link',
    name: 'Bank Accounts',
  },
];
export const centralApprovalRoutes = [
  {
    path: urls.central.approval.approvalSettings,
    icon: bsgear,
    type: 'link',
    name: 'Approval Settings',
  },
];
export const centralSettingRoutes = [
  {
    path: urls.central.communicationNotification.communicationTemplates,
    icon: central_commu_template,
    type: 'link',
    name: 'Communication Templates',
  },
  {
    path: urls.central.communicationNotification.notifications,
    icon: central_notification,
    type: 'link',
    name: 'Notification Settings',
  },
];
export const centralIntegrationRoutes = [
  {
    path: urls.central.integration.whatsapps,
    icon: central_whatsapp,
    type: 'link',
    name: 'Whatsapp settings',
  },
  {
    path: urls.central.integration.phones,
    icon: central_phone,
    type: 'link',
    name: 'Phone  Settings',
  },
];
