import { pause, play, voice } from "@components/application-panel/image";
import { convertSecondToHourMinuteSecond } from "@helper/admission";
import { Box } from "@mui/material";
import { useRef } from "react";
import StopIcon from '@mui/icons-material/Stop';
import useCountDown from "@hooks/useCountDown";

const PlayAudio = (props) => {
  const { className, url, duration } = props;
  const ref = useRef()
  const { start, stop, reset, resume, isStop, isRunning, duration: remaining } = useCountDown(duration)

  const onPlay = () => {
    ref.current.play()
    if (duration === remaining) {
      start()
    } else
      resume()
  }

  const onPause = () => {
    stop()
    ref.current.pause()
  }

  const onStop = () => {
    reset()
    ref.current.pause();
    ref.current.currentTime = 0;
  }

  return (
    <Box className="flex items-center mt-4">
      <audio ref={ref} src={url} />
      <img src={voice} alt={'voice'} />
      <img src={voice} alt={'voice'} />
      <img src={voice} alt={'voice'} />
      <img src={voice} alt={'voice'} className={'mr-12'} />

      <section className="flex items-center">
        {isRunning ? (
          <button onClick={onPause} className="w-6 aspect-square">
            <img src={pause} alt={'pause'} />
          </button>
        ) : (
          <button onClick={onPlay} className="w-6 aspect-square">
            <img src={play} alt={'play'} className="m-auto" />
          </button>
        )}
        <button onClick={onStop}>
          <StopIcon />
        </button>

        <p className="ml-3 text-center w-12 bg-[#00806029] text-[10px] text-[#00664D] font-bold rounded-xl px-2 py-1">
          {convertSecondToHourMinuteSecond(remaining)}
        </p>
      </section>
    </Box>
  )
}

export default PlayAudio;