import axios from 'axios';
// import { getLocalStorage, getSessionStorage } from '../utils/storage';
// import useStorage from 'store/storage';

export function getAccessToken() {
  const localStorageData = localStorage.getItem('persist:giiki1');
  if (!localStorageData) return null;
  const localStorageObj = JSON.parse(localStorageData);
  return localStorageObj?.state?.accessToken;
}

export function callApi(obj) {
  const { method, url, data, params, contentType, accessToken, headers, signal } = obj;
  const token = getAccessToken();
  const req = axios({
    method,
    url,
    data: data || {},
    params: params || {},
    headers: headers || {
      'content-type': contentType || 'application/json',
      accesstoken: accessToken || token,
    },
    signal,
  });

  return req;
}

export const handleCallApiFail = (error, title, cba = () => {}, cbb = () => {}) => {
  cba({
    status: 'error',
    title: title,
    message: error.response?.data?.message || error,
  });
  cbb(true);
};
