import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { MenuTask } from '../component';
import {
  UpdateTaskDate,
  UpdateTaskDependency,
  UpdateTaskGoal,
  UpdateTaskName,
  UpdateTaskOwner,
  UpdateTaskStatus,
  UpdateTaskTime,
} from '../component/update-task';
import NewTimer from './new-timer';
import { tree } from '@pages/tasks/image';
import {
  activeimg,
  done,
  eclips,
  ellipsis,
  headplus,
  mainavatar,
  msg,
} from '@static/image';
import { DateProgress, Input } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import { initialTask, statusList } from 'entities/data';
import './task-item.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const initialSelect = {
  name: false,
  status: false,
  owner: false,
  date: false,
  time: false,
  kri: false,
  dependency: false,
};
const goalsList = [
  {
    status: 'Excellent',
    color: '#fdab3d',
  },
  {
    status: 'Very good',
    color: '#00c875',
  },
  {
    status: 'Good',
    color: '#c4c4c4',
  },
  {
    status: 'Qualified',
    color: '#e2445c',
  },
];

function TaskItem(props) {
  const {
    burger,
    task = {},
    provided,
    index,
    id,
    selectedTeam,
    setRefresh,
    setIsEditTask,
    setSelectedTask,
    menuShow,
    setMenuShow,
    setPage,
    setNumber,
    setIsUpdate,
    setIndexSelectedTask,
  } = props;
  const [isSelect, setIsSelect] = useState(initialSelect);
  const [isShowSubTree, setIsShowSubTree] = useState(false);
  const [isClickOutside, setIsClickOutside] = useState(true);
  const [isShowMenuTask, setIsShowMenuTask] = useState(false);
  const [showModalAddNewTask, setshowModalAddNewTask] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  const [isEditTaskInfo, setIsEditTaskInfo] = useState(false);
  const [taskInfo, setTaskInfo] = useState(task);
  const [newTask, setNewTask] = useState(initialTask);
  const [taskDetail, updatetaskDetail] = useState(task);
  const [startTime, setStartTime] = useState(0);
  const [status, setStatus] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const user = useStorage((state) => state.auth);

  useEffect(() => {
    updatetaskDetail(task);
    setStartTime(task.minutesWorked);
  }, [task]);

  const findColor = useCallback((status) => {
    const result = statusList.find((data) => {
      return data.status === status;
    });
    if (!result) {
      return '#0E69C3';
    }
    return result.color;
  }, []);

  const handleDateTime = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toUTCString();
    return data.slice(5, 11);
  };

  const handleCancelAddTask = () => {
    // setListTasks(listTasksInDatabase);
    setNewTask(initialTask);
    setIsAddTask(false);
    setshowModalAddNewTask(false);
  };

  const onClickSaveHandle = () => {
    taskApi.createTask({ campusId: campus?.isCentral ? undefined : campus?.id }, newTask)
      .then((res) => {
        if (res?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Add New Task Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          handleCancelAddTask();
          setPage(0);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index: undefined,
          }));
          setIsAddTask(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Add New Task Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add New Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Add new task error', error);
      });
  };

  const handleAddNewTask = () => {
    if (newTask.name.trim().length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Name must not be empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    onClickSaveHandle();
    // setListTasks((prevState) => [newTask, ...prevState]);
    // setshowModalAddNewTask(true);
    // setIsAddTask(false);
  };

  const handlePercentsTask = (start, end, finish) => {
    const total = new Date(end) - new Date(start);
    let progress;
    if (finish) {
      progress = new Date(finish) - new Date(start);
    } else {
      progress = new Date() - new Date(start);
    }
    return (progress / total) * 100;
  };

  const checkManager = useMemo(() => {
    if (!selectedTeam || selectedTeam?.managers.length === 0) {
      return false;
    }
    const result = selectedTeam?.managers.find((manager) => {
      return manager?.user?.id === user?.uid;
    });
    return !!result;
  }, [selectedTeam, user]);

  return (
    <>
      <li
        className={clsx(
          'no-copy flex flex-nowrap items-center justify-start pl-4 mb-10 border rounded-lg w-[100%] bg-white relative',
          (taskDetail.subTasks && isShowSubTree) ||
            isAddTask ||
            showModalAddNewTask ||
            isEditTaskInfo
            ? ''
            : 'h-14',
          // index === 0 ? 'mt-10' : '',
        )}
      >
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <div className="flex items-center w-[26%] 2xl:w-[28%] relative">
          <img
            onClick={() => {
              setNumber(0);
              setSelectedTask(task);
              setIndexSelectedTask(index);
              setIsEditTask(true);
            }}
            src={eclips}
            className="w-4 h-4 m-5 cursor-pointer"
            alt=""
          />
          <img
            src={taskDetail.status === 'Done' ? done : activeimg}
            className="w-5 h-5 mr-2.5"
            alt=""
          />
          <p
            onDoubleClick={() => {
              setIsSelect({ ...initialSelect, name: !isSelect.name });
            }}
            className="text-second-gray w-52 mr-2.5 text-sm whitespace-pre overflow-hidden overflow-ellipsis cursor-pointer"
            data-for="main"
            data-tip={task.name}
            data-iscapture="true"
          >
            {task.name}
          </p>
          <UpdateTaskName
            task={task}
            setTaskInfo={setTaskInfo}
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
          {isAddTask && (
            <div className="absolute top-12 left-[-1rem] w-[30rem] rounded-lg mt-4 mb-6 shadow-[15px_8px_30px_rgba(0,0,0,0.1)] bg-[#fff] z-20">
              <div className="flex my-6 ml-4">
                <img
                  onClick={() => {
                    handleAddNewTask();
                  }}
                  src={headplus}
                  className="mr-3 mt-2 cursor-pointer w-6 h-6"
                  alt=""
                />
                <Input
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddNewTask();
                    }
                  }}
                  onChange={(e) => {
                    // setUser({ ...user, firstName: e.target.value });
                    setNewTask((prevState) => ({
                      ...prevState,
                      name: e.target.value.trim(),
                      teamId: selectedTeam.id,
                      fatherTaskId: id,
                      startDate: new Date(),
                      endDate: new Date(),
                    }));
                  }}
                  label="Add Task Name"
                  name="add-task-name"
                  type="text"
                  // value=""
                  customStyle={{
                    width: '25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                // error={error.statusFirstName}
                />
              </div>
            </div>
          )}
          <button className="w-[5.75rem]">
            <img
              src={msg}
              // className="w-12 h-12 px-4"
              className={clsx(
                'h-12 w-10 px-3',
                // taskDetail.subTasks && taskDetail.subTasks.length > 0
                //   ? burger
                //     ? 'w-12 px-3'
                //     : 'w-10 px-3'
                //   : 'w-12 px-4',
              )}
              alt=""
              onClick={() => {
                setNumber(1);
                setSelectedTask(task);
                setIsEditTask(true);
              }}
            />
          </button>
          <button
            className={clsx(
              'w-[5rem] h-8 px-2',
              taskDetail.subTasks && taskDetail.subTasks.length > 0 ? '' : 'invisible',
            )}
            onClick={() => {
              setIsShowSubTree(!isShowSubTree);
            }}
          >
            <img src={tree} className="w-4 h-4 mr-1" alt="" />
          </button>
          {/* {taskDetail.subTasks && taskDetail.subTasks.length > 0 && (
            <button
              className="w-[5rem] h-8 px-2"
              onClick={() => {
                setIsShowSubTree(!isShowSubTree);
              }}
            >
              <img src={tree} className="w-4 h-4 mr-1" alt="" />
            </button>
          )} */}
        </div>
        <div
          className="flex items-center w-[9%] relative cursor-pointer"
          data-for="main"
          data-tip={status || taskDetail.status}
          data-iscapture="true"
        >
          <div
            className="h-2 w-2 2xl:w-1 2xl:h-1 rounded-full mr-3"
            style={{ backgroundColor: findColor(status || taskDetail.status) }}
          />
          <p
            className=""
            onDoubleClick={() => {
              setIsSelect({ ...initialSelect, status: !isSelect.status });
            }}
          >
            {status || taskDetail.status}
          </p>
          <UpdateTaskStatus
            task={task}
            setTaskInfo={setTaskInfo}
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
        </div>
        <div className="flex justify-center w-[7%] cursor-pointer">
          <div
            onDoubleClick={() => {
              setIsSelect({ ...initialSelect, owner: !isSelect.owner });
            }}
            className="flex -space-x-2 items-center relative"
          >
            {taskDetail.assignees.length < 2 ? (
              taskDetail.assignees.map((member) => {
                return (
                  <img
                    data-for="main"
                    data-tip={member?.member?.user?.name || 'User Name'}
                    data-iscapture="true"
                    key={member.member.id}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={member?.member?.user?.photoURL || mainavatar}
                    alt=""
                  />
                );
              })
            ) : taskDetail.assignees.length >= 2 ? (
              [0, 1].map((key) => {
                return key !== 1 ? (
                  <img
                    data-for="main"
                    data-tip={
                      taskDetail?.assignees?.[key]?.member?.user?.name || 'User Name'
                    }
                    data-iscapture="true"
                    key={key}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={
                      taskDetail?.assignees?.[key]?.member?.user?.photoURL || mainavatar
                    }
                    alt=""
                  />
                ) : (
                  <div
                    key={key}
                    className="flex items-center justify-center bg-green-200 text-sm h-8 w-8 rounded-full ring-2 ring-white"
                  >
                    +{taskDetail.assignees.length - 1}
                  </div>
                );
              })
            ) : (
              <div>-</div>
            )}
            <UpdateTaskOwner
              task={task}
              setTaskInfo={setTaskInfo}
              initialSelect={initialSelect}
              isSelect={isSelect}
              setIsSelect={setIsSelect}
              index={index}
              setRefresh={setRefresh}
              members={selectedTeam?.members}
              setIsUpdate={setIsUpdate}
              setPage={setPage}
            />
          </div>
        </div>
        <div
          onDoubleClick={() => {
            setIsSelect({ ...initialSelect, date: !isSelect.date });
          }}
          className="w-[140px] 1920px:w-[160px] relative cursor-pointer"
        >
          {!taskDetail.finishedDate &&
            taskDetail.endDate &&
            new Date() > new Date(taskDetail.endDate) ? (
            <div className="flex flex-col items-center">
              <p className="text-second-gray text-sm">
                {moment(taskDetail.endDate).fromNow().replace('ago', 'late')}
              </p>
              <div
                className="rounded-lg border w-140 mb-1"
                style={{
                  height: '0.438rem',
                  background: `linear-gradient(to right, #eb5757 100%, #fff 90%)`,
                }}
              />
            </div>
          ) : taskDetail.finishedDate && taskDetail.finishedDate > taskDetail.endDate ? (
            <div className="flex flex-col items-center">
              <p className="text-second-gray text-sm">
                {moment(taskDetail.finishedDate)
                  .from(taskDetail.endDate)
                  .replace('in', '') + ' late'}
              </p>
              <div
                className="rounded-lg border w-140 mb-1"
                style={{
                  height: '0.438rem',
                  background: `linear-gradient(to right, #eb5757 100%, #fff 90%)`,
                }}
              />
            </div>
          ) : (
            <DateProgress
              date={
                handleDateTime(taskDetail.startDate) +
                ' - ' +
                handleDateTime(taskDetail.endDate)
              }
              num={handlePercentsTask(
                taskDetail.startDate,
                taskDetail.endDate,
                taskDetail.finishedDate,
              )}
            />
          )}
          <UpdateTaskDate
            task={task}
            setTaskInfo={setTaskInfo}
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
        </div>
        <div
          onDoubleClick={() => {
            setIsSelect({ ...initialSelect, time: !isSelect.time });
          }}
          className={clsx(
            'w-[16%] cursor-pointer relative',
            burger
              ? '2xl:pl-3 1400px:pl-4 1920px:pl-12'
              : 'pl-5 1400px:pl-8 1920px:pl-16',
          )}
        >
          <NewTimer
            taskDetail={taskDetail}
            updatetaskDetail={updatetaskDetail}
            isWorking={taskDetail.isWorking}
            startWorkTime={
              taskDetail.isWorking
                ? (new Date().getTime() - new Date(taskDetail.startWorkTime).getTime()) /
                60000
                : 0
            }
            // startTime={taskDetail?.minutesWorked || 0}
            // endTime={taskDetail?.minutesEstimate || 0}
            setStartTime={setStartTime}
            setStatus={setStatus}
          // handleUpdateTimeWorkOfTask={handleUpdateTimeWorkOfTask}
          />
          <UpdateTaskTime
            task={task}
            setTaskInfo={setTaskInfo}
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
        </div>
        {/* <Timer sec={taskDetail.time} /> */}
        <div
          onDoubleClick={() => {
            setIsSelect({ ...initialSelect, kri: !isSelect.kri });
          }}
          className={clsx(
            'relative cursor-pointer border rounded-md flex items-center justify-center w-[12%]',
            burger ? '1400px:ml-2 1920px:ml-8' : '1400px:ml-4 1920px:ml-10',
          )}
          style={{
            height: '1.5rem',
            borderColor:
              taskDetail.goalsOrKRI === 'Better Experience' ? '#008060' : '#EB5757',
            color: taskDetail.goalsOrKRI === 'Better Experience' ? '#008060' : '#EB5757',
          }}
        >
          <p
            className="text-xs whitespace-pre pr-2 pl-2 overflow-hidden overflow-ellipsis"
          // style={{ color: taskDetail.color }}
          >
            {taskDetail.goalsOrKRI}
          </p>
          <UpdateTaskGoal
            task={task}
            setTaskInfo={setTaskInfo}
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
        </div>
        <div
          onDoubleClick={() => {
            setIsSelect({ ...initialSelect, dependency: !isSelect.dependency });
          }}
          className="w-[10%] 2xl:w-[11%] pl-4 2xl:pl-8 mr-7 relative cursor-pointer"
          data-for="main"
          data-tip={taskDetail.dependency}
          data-iscapture="true"
        >
          <p className="text-xs whitespace-pre overflow-hidden overflow-ellipsis">
            {taskDetail.dependency}
          </p>
          <p className="text-sm" style={{ color: 'rgba(99, 115, 129, 0.6)' }}>
            Due on{' '}
            <span style={{ color: '#27C59A' }}>
              {handleDateTime(taskDetail.dueDateDependency)}
            </span>
          </p>
          <UpdateTaskDependency
            initialSelect={initialSelect}
            isSelect={isSelect}
            setIsSelect={setIsSelect}
            task={task}
            index={index}
            setRefresh={setRefresh}
            setIsUpdate={setIsUpdate}
            setPage={setPage}
          />
        </div>
        <div className="relative z-10">
          <img
            onClick={() => {
              setIsShowMenuTask(!isShowMenuTask);
            }}
            className="cursor-pointer py-2 px-3 "
            src={ellipsis}
            alt="delegate"
          ></img>
          {isShowMenuTask && (
            <MenuTask
              checkManager={checkManager}
              setIsAddTask={setIsAddTask}
              isAddTask={isAddTask}
              setIsShowMenuTask={setIsShowMenuTask}
              setTaskInfo={setTaskInfo}
              task={task}
              setIsEditTaskInfo={setIsEditTaskInfo}
              setMenuShow={setMenuShow}
              setSelectedTask={setSelectedTask}
              setRefresh={setRefresh}
              index={index}
            />
          )}
        </div>
        {/* {taskDetail.subTasks && isShowSubTree && (
          <div className="pl-4 w-full mt-10 max-h-[50vh] overflow-y-scroll overflow-x-hidden">
            <DragDropDemoDetail
              burger={burger}
              dragContext={taskDetail.subTasks}
              id={id}
              selectedTeam={selectedTeam}
              setRefresh={setRefresh}
              setIsEditTask={setIsEditTask}
              setSelectedTask={setSelectedTask}
              setMenuShow={setMenuShow}
              menuShow={menuShow}
              setPage={setPage}
              setNumber={setNumber}
              setIsUpdate={setIsUpdate}
            />
          </div>
        )} */}
      </li>
      <li className="relative">
        {taskDetail.subTasks && isShowSubTree && (
          <div
            className={clsx(
              'absolute left-4 top-0 w-[0.5px] bg-[#B0B6BE]',
              taskDetail.subTasks.length === 1 ? 'h-[60%]' : 'h-[90%]',
            )}
          ></div>
        )}
        {taskDetail.subTasks && isShowSubTree && (
          <div className="pl-4 w-full mt-10 max-h-[50vh] overflow-y-scroll overflow-x-hidden">
            <DragDropDemoDetail
              burger={burger}
              dragContext={taskDetail.subTasks}
              id={id}
              selectedTeam={selectedTeam}
              setRefresh={setRefresh}
              setIsEditTask={setIsEditTask}
              setSelectedTask={setSelectedTask}
              setMenuShow={setMenuShow}
              menuShow={menuShow}
              setPage={setPage}
              setNumber={setNumber}
              setIsUpdate={setIsUpdate}
            />
          </div>
        )}
      </li>
    </>
  );
}

export default TaskItem;

function DragDropDemoDetail({
  burger,
  dragContext,
  id,
  selectedTeam,
  setRefresh,
  setIsEditTask,
  setSelectedTask,
  menuShow,
  setMenuShow,
  setPage,
  setNumber,
  setIsUpdate,
}) {
  const [tasks, updatetasks] = useState([]);

  useEffect(() => {
    updatetasks(dragContext);
  }, [dragContext]);

  return (
    <ul className="tasks pl-4 w-full max-h-[50vh] overflow-y-scroll overflow-x-hidden">
      {tasks.map((item, index) => {
        return (
          <TaskItem
            burger={burger}
            key={index}
            task={item}
            index={index}
            id={item.id}
            selectedTeam={selectedTeam}
            setRefresh={setRefresh}
            setIsEditTask={setIsEditTask}
            setSelectedTask={setSelectedTask}
            setMenuShow={setMenuShow}
            menuShow={menuShow}
            setPage={setPage}
            setNumber={setNumber}
            setIsUpdate={setIsUpdate}
          />
        );
      })}
    </ul>
  );
}

// ******* NO DELETE ***************

/* <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="taskDetail">
              {(provided) => (
                <ul
                  className="tasks pl-10 w-full"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {taskDetail.sub.map((item, index) => {
                    return (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          // <TaskItem provided={provided} task={item} />
                          <li
                            className="flex items-center justify-between pr-4 pl-4 mt-10 border rounded-lg w-full h-14 bg-white overflow-hidden"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ReactTooltip
                              id="main"
                              place="top"
                              type="dark"
                              effect="float"
                              multiline={true}
                            />
                            <div className="flex items-center">
                              <button className="mr-4">
                                <img src={eclips} className="w-4 h-4" alt="" />
                              </button>
                              <img
                                src={item.done === true ? done : activeimg}
                                className="w-5 h-5 mr-2.5"
                                alt=""
                              />
                              <p
                                className="text-second-gray w-52 mr-2.5 text-sm whitespace-pre overflow-hidden overflow-ellipsis"
                                data-for="main"
                                data-tip="Complete Designs for all Screens"
                                data-iscapture="true"
                              >
                                Complete Designs for all Screens
                              </p>
                              <button>
                                <img src={msg} className="w-4 h-4 mr-2" alt="" />
                              </button>
                              <button>
                                <img src={tree} className="w-4 h-4 mr-1" alt="" />
                              </button>
                            </div>
                            <div
                              className="flex items-center"
                              data-for="main"
                              data-tip="In Progress"
                              data-iscapture="true"
                            >
                              <div className="h-2 w-2 2xl:w-1 2xl:h-1 rounded-full bg-yellow-600 mr-3" />
                              <p className="">In Progress</p>
                            </div>
                            <div className="flex justify-center">
                              <img src={mainavatar} className="w-8 h-8" alt="" />
                            </div>
                            <div className="flex flex-col items-center">
                              <p className="text-second-gray text-sm">{item.date}</p>
                              <div
                                className="rounded-lg border w-140 mb-1"
                                style={{
                                  height: '0.438rem',
                                  background: `linear-gradient(to right, #27C59A ${
                                    item.num
                                  }%, #fff ${item.num - 10}%)`,
                                }}
                              />
                            </div>
                            <Timer sec={item.time} />
                            <div
                              className="border rounded-md flex items-center justify-center"
                              style={{ height: '1.5rem', borderColor: item.color }}
                            >
                              <p
                                className="text-xs whitespace-pre pr-2 pl-2 overflow-hidden overflow-ellipsis"
                                style={{ color: item.color }}
                              >
                                Better Experience
                              </p>
                            </div>
                            <div
                              className="w-32"
                              data-for="main"
                              data-tip="Requirment Documents...<br />Due on 20thOct"
                              data-iscapture="true"
                            >
                              <p className="text-xs whitespace-pre overflow-hidden overflow-ellipsis">
                                Requirment Documents
                              </p>
                              <p
                                className="text-sm"
                                style={{ color: 'rgba(99, 115, 129, 0.6)' }}
                              >
                                Due on <span style={{ color: '#27C59A' }}>20th Oct</span>
                              </p>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext> */
