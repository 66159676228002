const { callApi } = require('@helper/call-api');

class AdmissionApi {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission`;
  }

  getChart = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/data-chart`,
      params,
    });
  };

  getTotalEnquiry = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/total`,
      params,
    });
  };

  getTopCounselors = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/top-counselors`,
      params,
    });
  };

  getTopReferral = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/top-referals`,
      params,
    });
  };

  getMyData = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/my-data`,
      params,
    });
  };

  getListStage = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/stage`,
      params,
    });
  };

  getListStageForParent = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/stage/parent`,
      params,
    });
  };

  updateStages = async (params, data) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/enquiry/stage`,
      params,
      data,
    });
  };

  getEnquiry = async ({ campusId, enquiryId, signal }) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/${enquiryId}`,
      params: { campusId },
      signal,
    });
  };

  updateEnquiryById = async (data, enquiryId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/enquiry/${enquiryId}`,
      data,
    });
  };

  getParentOfEnquiry = async ({ enquiryId, signal }) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/enquiry-parent/${enquiryId}`,
      signal,
    });
  };

  getEnquiryParent = async (enquiryId, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/enquiry/parent/${enquiryId}`,
      signal,
    });
  };

  updateApplicationParent = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/parent`,
      params,
      data,
    });
  };

  updateApplication = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application`,
      params,
      data,
    });
  };

  getQuery = async (params, enquiryId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/${enquiryId}`,
      params,
    });
  };

  getPaymentLink = async (applicationId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/payment-link/${applicationId}`,
    });
  };

  signature = async (params, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/signature/${applicationId}`,
      params,
    });
  };

  signatureFeePayment = async (params, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/signature/fee-payment/${applicationId}`,
      params,
    });
  };

  confirmSignature = async (params, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/parent-signature/confirm/${applicationId}`,
      params,
    });
  };

  createApplicationExam = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/exam`,
      params,
      data,
    });
  };

  createApplicationExamParent = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/exam/parent`,
      params,
      data,
    });
  };

  updateApplicationExam = async (params, data, examId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/exam/${examId}`,
      params,
      data,
    });
  };

  updateApplicationExamParent = async (params, data, examId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/exam/parent/${examId}`,
      params,
      data,
    });
  };

  updateApplicationExamResult = async (params, data, examId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/exam/result/${examId}`,
      params,
      data,
    });
  };

  createApplicationPrincipal = async (data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/principal`,
      data,
    });
  };

  updateApplicationPrincipalComplete = async (principalApprovalId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/principal/complete/${principalApprovalId}`,
    });
  };

  uploadApplicationDocumentParent = async (data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/document/parent`,
      data,
    });
  };

  updateApplicationDocument = async (data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/document`,
      data,
    });
  };

  getListDocument = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/document/list`,
      params,
      signal,
    });
  };

  getListDocumentParent = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/document/parent/list`,
      params,
      signal,
    });
  };

  updateApplicationDocumentById = async (data, documentId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/document/${documentId}`,
      data,
    });
  };

  updateApplicationDocumentParentById = async (data, documentId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/parent/${documentId}`,
      data,
    });
  };

  deleteApplicationDocumentById = async (documentId) => {
    return callApi({
      method: 'delete',
      url: `${this.baseUrl}/application/document/${documentId}`,
    });
  };

  deleteApplicationDocumentParentById = async (documentId) => {
    return callApi({
      method: 'delete',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/document/parent/${documentId}`,
    });
  };

  getAdmissionOverview = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/overview`,
      params,
    });
  };

  updateCustomStage = async (params, data, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/custom-stage/${applicationId}`,
      params,
      data,
    });
  };

  updateCustomStageParent = async (params, data, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/custom-stage/parent/${applicationId}`,
      params,
      data,
    });
  };

  getListScholarship = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/scholarship/list`,
      params,
    });
  };

  getListScholarshipParent = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/scholarship/parent/list`,
      params,
    });
  };

  getListFeeBook = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/scholarship/feebook`,
      params,
      signal,
    });
  };

  createAdmissionCalendar = async ({ data }) => {
    return await callApi({
      method: 'post',
      url: `${this.baseUrl}/calendar`,
      data,
    });
  };

  updateAdmissionCalendar = async ({ id, data }) => {
    return await callApi({
      method: 'patch',
      url: `${this.baseUrl}/calendar/${id}`,
      data,
    });
  };

  getListAdmissionCalendarByDate = async ({ campusId, startDate, endDate }) => {
    // startDate: mm/dd/yyyy
    // endDate: mm/dd/yyyy
    return await callApi({
      method: 'get',
      url: `${this.baseUrl}/calendar/list-by-date`,
      params: { campusId, startDate, endDate },
    });
  };

  getListAdmissionCalendar = async ({ campusId, enquiryId }) => {
    return await callApi({
      method: 'get',
      url: `${this.baseUrl}/calendar/list`,
      params: { campusId, enquiryId },
    });
  };

  checkEmailVerify = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/email/verify-email`,
      params,
    });
  };

  getListEmail = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/email/list`,
      params,
    });
  };

  getListEmailTemplate = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/email/template/list`,
      params,
    });
  };

  sendMail = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/email/sendEmail`,
      data,
    });
  };

  sendForStaffSignature = async (data, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/staff-signature/${applicationId}`,
      data,
    });
  };

  confirmParentSignature = async (params, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/confirm/${applicationId}`,
      params,
    });
  };

  sendForSignature = async (data, userId, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/application/${
        userId ? 'staff-signature' : 'parent-signature'
      }/${applicationId}`,
      data,
    });
  };

  updateApplicationScholarship = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/scholarship`,
      params,
      data,
    });
  };
  updateApplicationScholarshipParent = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/scholarship/parent`,
      params,
      data,
    });
  };

  deleteApplicationScholarship = async (params, data, applicationId) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/application/scholarship/${applicationId}`,
      params,
      data,
    });
  };

  deleteApplicationScholarshipParent = async (params, data, applicationId) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/scholarship/parent/${applicationId}`,
      params,
      data,
    });
  };

  deleteApplicationScholarshipParent1 = async (params, data, applicationId) => {
    return callApi({
      method: 'delete',
      url: `${this.baseUrl}/application/scholarship/parent/${applicationId}`,
      params,
      data,
    });
  };

  getListEnquiryParent = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/enquiry/parent-list`,
    });
  };

  uploadMedia = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
      data,
      contentType,
    });
  };

  uploadMediaFile = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media/file`,
      data,
      contentType,
    });
  };

  getListGrade = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/grade/program/list`,
      params,
    });
  };

  getListAcademicYear = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/academic/list`,
      params,
    });
  };

  createNewEnquiry = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/enquiry`,
      params,
      data,
    });
  };

  getListCampusOfUser = async () => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/campus/user`,
    });
  };

  paymentOrder = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/fee-payment/payment-oder`,
      params,
      data,
    });
  };

  getPaymentOrderById = async (params, paymentId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/fee-payment/payment-order/${paymentId}`,
      params,
    });
  };

  updatePaymentInfo = async (params, data, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/fee-payment/payment-information/${applicationId}`,
      params,
      data,
    });
  };

  getPaymentInfo = async (params, applicationId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/fee-payment/payment-information/${applicationId}`,
      params,
    });
  };

  updatePaymentLink = async (params, data, applicationId) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/fee-payment/payment-link/${applicationId}`,
      params,
      data,
    });
  };

  getListEnquirySearch = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/search`,
      params,
    });
  };

  getListEnquiry = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/enquiry/list`,
      params,
      signal,
    });
  };

  updateSignature = async (applicationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/${applicationId}`,
    });
  };

  getListClass = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/grade/list-programs-and-classes`,
      params,
    });
  };

  getApplicationPayment = async (applicationId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/application/application-payment/${applicationId}`,
    });
  };
}

const admissionApi = new AdmissionApi();
export default admissionApi;
