import React, { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router-dom';
import {
  selectStylesWithTitle,
  selectStylesApprovers,
} from '../../entities/select-style';
import { newPlus } from '@static/image';
import { dataLinks } from '../../entities/routes';
import { mainavatar, staticsize } from '../../static/image';
import { Button, Input, SelectGroup, Upload } from '../../stories';
import Chip from '../../stories/chip/chip.jsx';
import { NavLinks } from '../Header/components/index';
import { ToastMessageContext } from '../../context/toast-context';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
} from '../../utils/check-error/api-error';
import './edit-user.scss';
import authApi from 'api/auth';
import centralAdminApi from 'api/central-admin';
import { companyStatus } from '@utils/constant';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const USER_ROLE = {
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
};

const colorOption = ['#404EED', '#663174', '#F2C94C', '#00AB55', '#FFA48D'];

const styles = {
  btnBlue: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  btnWhite: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
  },
  btnAddLocation: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    border: '1px solid #404eed',
    margin: 'auto',
  },
};

export default function EditUser(props) {
  const { burger, selectUser, company, campus, isParent, isTeacher, isStudent } = props;
  const [imgPreview, setImgPreview] = useState('');
  const [user, setUser] = useState(selectUser);
  const [userRoles, setUserRoles] = useState([]);
  const [listRole, setListRole] = useState([{}]);
  const [listStudent, setListStudent] = useState([{}]);
  const [listStudentOfParent, setListStudentOfParent] = useState([{}]);
  const [isLogo, setIsLogo] = useState(false);
  const [dataLocationAndRole, setDataLocationAndRole] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [listLocations, setListLocations] = useState([]);
  const [formData, setFormData] = useState(new FormData());

  const [error] = useState({
    statusFirstName: false,
    errorMessageFirstName: 'Please enter the first name',
    statusFullName: false,
    errorMessageFullName: 'Please enter the first name',
    statusLastName: false,
    errorMessageLastName: 'Please enter the last name',
    statusEmail: false,
    errorMessageEmail: 'Please enter the email',
    statusPhoneNumber: false,
    errorMessagePhoneNumber: 'Please enter the phone number',
  });
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    authApi
      .getAllRoleOfCompanyOrCampus({
        companyId: typeof company === 'string' ? company : campus.companyId,
        campusId: campus?.isCentral ? undefined : campus?.id,
      })
      .then((results) => {
        if (
          checkErrorApiFetch(results, setToastMessage, setIsShowToastMessage, 'Get Role')
        ) {
          setListRole(() => {
            const datas = [];
            results.data.data.forEach((role) => {
              if (role.name !== USER_ROLE.STUDENT && role.name !== USER_ROLE.PARENT) {
                const data = {
                  value: role.id,
                  label: role.name,
                  info: role,
                };
                datas.push(data);
              }
            });
            return datas;
          });
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Role Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit User Error', error);
      });
  }, []);

  useEffect(() => {
    if (isParent) {
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/user/get-users-of-company-or-campus`,
      //   params: {
      //     companyId: company?.id,
      //     campusId: campus?.isCentral ? undefined : campus?.id,
      //     filterRoleNames: USER_ROLE.STUDENT,
      //   },
      // })
      centralAdminApi
        .getUserOfCompanyOrCampus({
          companyId: company?.id,
          campusId: campus?.isCentral ? undefined : campus?.id,
          filterRoleNames: USER_ROLE.STUDENT,
        })
        .then((results) => {
          if (
            checkErrorApiFetch(
              results,
              setToastMessage,
              setIsShowToastMessage,
              'Get Users',
            )
          ) {
            const data = results.data.data.objects.map((user, key) => {
              return {
                value: user.id,
                label: user.name,
                color: colorOption[key % colorOption.length],
                info: user,
              };
            });
            setListStudent(data);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Users Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Edit User Error', error);
        });
    }
  }, [isParent]);

  useEffect(() => {
    setImgPreview(selectUser.photoURL);
    if (!!selectUser) {
      setUser(selectUser);
    }
    if (Array.isArray(selectUser.userRoles)) {
      const listRoles = [];
      selectUser.userRoles.forEach((role) => {
        const roleOpt = {
          value: role.roles.id,
          label: role.roles.name,
        };
        listRoles.push(roleOpt);
      });
      setUserRoles(listRoles);
    }
    if (isParent) {
      if (Array.isArray(selectUser?.detail?.studentParents)) {
        const listChildren = selectUser.detail.studentParents.map((data, key) => {
          return {
            value: data.student.id,
            label: data.student.middleName
              ? `${data.student.firstName} ${data.student.middleName} ${data.student.lastName}`
              : `${data.student.firstName} ${data.student.lastName}`,
            color: colorOption[key % colorOption.length],
          };
        });
        setListStudentOfParent(listChildren);
      } else {
        setListStudentOfParent([{}]);
      }
    }

    // load list Roles and Locations
    if (!_.isEmpty(selectUser)) {
      let getListLocations;
      if (!campus) {
        getListLocations = authApi.getListCompany({
          companyId: company,
          status: companyStatus[0],
        });
      } else {
        getListLocations = authApi.getListCampusUser({
          companyId: typeof company === 'string' ? company : campus.companyId,
          isGetHQCampus: true,
          isGetAll: true,
        });
      }
      const getListRole = authApi.getAllRoleOfCompanyOrCampus({
        companyId: typeof company === 'string' ? company : campus.companyId,
        campusId: campus?.id,
      });
      Promise.all([getListRole, getListLocations])
        .then((res) => {
          if (
            checkErrorMultipleApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Fetch List Role Or List Campus',
            )
          ) {
            let listRoles;
            let listLocationsFromApi;
            if (res?.[0]?.data?.data?.length) {
              listRoles = res[0].data.data.map((data, key) => ({
                label: data.name,
                value: data.id,
                color: colorOption[key % colorOption.length],
              }));
              setListRoles(listRoles);
            }
            if (res?.[1]?.data?.data?.objects?.length) {
              if (!campus) {
                const companise = res[1].data.data.objects.map(data => ({
                  value: data.id,
                  label: data.name,
                  info: data,
                  isCampus: false,
                }));
                const campuses = res[1].data.data.objects.reduce((acc, item) => {
                  return [...acc, ...item.campus.map(campus => ({
                    value: campus.id,
                    label: campus.name,
                    info: campus,
                    isCampus: true,
                  }))];
                }, [])
                listLocationsFromApi = [...companise, ...campuses];
                setListLocations([{ label: 'Company', options: companise }, { label: 'Campus', options: campuses }]);
              } else {
                const campuses = res[1].data.data.objects.map(data => ({
                  value: data.id,
                  label: data.name,
                  info: data,
                  isCampus: true
                }));
                listLocationsFromApi = campuses;
                setListLocations([{ label: 'Campus', options: campuses }]);
              }
            }
            // Map Roles and Locations into dataLocationAndRole
            const tmp = {};
            selectUser.roles.forEach((role) => {
              const locationId = role.campus?.id || role.company.id; // in case edit user of Company, the role.campus will be null, and we will retrieve the location id from role.company.id
              const roleId = role.roles.id;
              const ro = listRoles.find((r) => r.value === roleId);
              if (tmp[locationId]) {
                tmp[locationId].push(ro);
              } else
                tmp[locationId] = [ro];
            });
            const tmp1 = Object.keys(tmp).map((key) => ({
              location: listLocationsFromApi.find((l) => l.value === key),
              role: tmp[key],
              isErrorLocation: undefined,
              isErrorRole: undefined,
            }));
            setDataLocationAndRole(tmp1);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Roles Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Create User Error', error);
        });
    }
  }, [selectUser]);

  const formatOptionLabelUser = ({ label, info, isCampus }) => (
    <div className="block relative">
      <Chip
        key={info?.id}
        label={label}
        image={info?.photoURL || mainavatar}
        chipAvatarStyle={{ width: 18, height: 18 }}
        styleChip={'object-cover'}
      />
      {!isCampus && <AutoAwesomeIcon style={{ color: 'yellow', position: 'absolute', top: '-5px', right: '8px', fontSize: '1rem' }} />}
    </div>
  );

  const formatOptionLabelChildren = ({ label, info }) => (
    <Chip
      key={info?.id}
      label={label}
      image={info?.photoURL || mainavatar}
      chipAvatarStyle={{ width: 18, height: 18 }}
      styleChip={'object-cover'}
    />
  );

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      setIsLogo(true);
      formData.set('file', file);
      formData.set('mediaId', file.name);
      setFormData(formData);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImgPreview(reader.result);
      };
      e.target.value = null;
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const handlerOnClickCancel = () => {
    setImgPreview(selectUser.photoURL);
    setUser(selectUser);
    if (Array.isArray(selectUser.userRoles)) {
      const listRoles = [];
      selectUser.userRoles.forEach((role) => {
        const roleOpt = {
          value: role.roles.id,
          label: role.roles.name,
        };
        listRoles.push(roleOpt);
      });
      setUserRoles(listRoles);
    } else {
      setUserRoles([{}]);
    }
    if (isParent && Array.isArray(selectUser?.detail?.studentParents)) {
      const listChildren = selectUser.detail.studentParents.map((data, key) => {
        return {
          value: data.student.userId,
          label: data.student.middleName
            ? `${data.student.firstName} ${data.student.middleName} ${data.student.lastName}`
            : `${data.student.firstName} ${data.student.lastName}`,
          color: colorOption[key % colorOption.length],
        };
      });
      setListStudentOfParent(listChildren);
    } else {
      setListStudentOfParent([{}]);
    }
    history.goBack();
  };

  const handleOnClickSave = async () => {
    const length = dataLocationAndRole.length;
    for (let i = 0; i < length; ++i) {
      if (!dataLocationAndRole[i]?.location) {
        setToastMessage({
          status: 'error',
          title: 'Missing Field',
          message: 'Missing Location',
        });
        setIsShowToastMessage(true);
        return;
      }
      if (_.isArray(dataLocationAndRole[i].role)) {
        if (!dataLocationAndRole[i]?.role?.length) {
          setToastMessage({
            status: 'error',
            title: 'Missing Field',
            message: 'Missing Role',
          });
          setIsShowToastMessage(true);
          return;
        }
      }
    }
    let logo = '';
    if (isLogo) {
      try {
        // const resultUploadImage = await callApi({
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`,
        //   data: formData,
        //   contentType: 'multipart/form-data',
        // });
        const resultUploadImage = await centralAdminApi.uploadMedia(formData);
        if (resultUploadImage.data.success) {
          logo = resultUploadImage.data.data;
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Image Failed',
            message: resultUploadImage.data.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Upload Image Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit User Error', error);
      }
    }

    // map the location and role
    // const listDataLocationAndRoleCallApi = dataLocationAndRole.map((item) => {
    //   const listRoleIds = _.isArray(item.role) ? item.role : item.role ? [item.role] : [];

    //   // const listRoleIdsCallApi = (listRoleIds || []).map(role => {
    //   const listRoleIdsCallApi = listRoleIds.map((role) => {
    //     return role.value;
    //   });
    //   return {
    //     location: {
    //       id: item.location.value,
    //       isCampus: item.location.isCampus,
    //     },
    //     roles: listRoleIdsCallApi || [],
    //   };
    // });

    const listDataLocationAndRoleCallApi1 = dataLocationAndRole.reduce((acc, item) => {
      const tmp = []
      item.role.forEach((role) => {
        tmp.push({
          location: {
            id: item.location.value,
            isCampus: item.location.isCampus,
          },
          roles: [role.value],
        })
      })
      return [...acc, ...tmp]
    }, []);


    const data = {
      user: {
        email: user.email,
        name: user.name,
        phoneNumber: user.phoneNumber,
        photoURL: logo ? logo : user.photoURL,
        dataLocationAndRole: listDataLocationAndRoleCallApi1,
        // old code, keep for referal only
        // roleIds: userRoles
        //   ? userRoles.map((role) => {
        //       return role.value;
        //     })
        //   : user.userRoles.map((role) => {
        //       return role.roles.id;
        //     }),
      },
    };
    if (isStudent) {
      data.user.name = user?.middleName
        ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
        : `${user?.firstName} ${user?.lastName}`;
      data.student = {
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        preferredName: user?.preferredName,
        otherName: user?.otherName,
        gender: user?.gender,
        dob: user?.dob,
        language: user?.language,
        nationalId: user?.nationalI,
      };
    } else if (isParent) {
      data.user.name = user?.middleName
        ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
        : `${user?.firstName} ${user?.lastName}`;
      data.parent = {
        status: user?.status,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        preferredName: user?.preferredName,
        gender: user?.gender,
        studentUserIds:
          listStudentOfParent.length && Object.keys(listStudentOfParent[0]).length
            ? listStudentOfParent.map((student) => student.value)
            : [],
      };
    } else if (isTeacher) {
      data.user.name = user?.middleName
        ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
        : `${user?.firstName} ${user?.lastName}`;
      data.teacher = {
        status: user?.status,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
      };
    }
    authApi
      .updateUserById(data, user.id)
      .then((response) => {
        if (response.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Update User Successfully',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
          history.goBack();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update User Failed',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        console.log('Edit User Error', error);
        setToastMessage({
          status: 'error',
          title: 'Update User Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  const onRemove = (index) => {
    setDataLocationAndRole((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="edit-user px-8 w-full">
      <div className="min-w-[50%]">
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.editUserLinks} />
          <h1 className="namepage">Edit User</h1>
          <div className="edit-user-form">
            <div className="edit-user-form_section edit-user-section">
              <div className="edit-user-section_header">
                <h2 className="edit-user-section_title">1. User Photo</h2>
                <p className="edit-user-section_text mt-2">
                  Upload a user image to make it easily recognizable.
                </p>
              </div>
              <div className="edit-user-upload">
                <img
                  className="edit-user-upload_image"
                  src={imgPreview || staticsize}
                  alt="uploaded"
                />
                <Upload
                  text="Upload Image"
                  id="image-upload"
                  accept="image/*"
                  imageChange={setImgPreview}
                  onChange={(e) => {
                    changeImageHandler(e);
                  }}
                />
              </div>
            </div>
            <div className="edit-user-form_section edit-user-section">
              <div className="edit-user-section_header">
                <h2 className="edit-user-section_title">2. User Details</h2>
                <div className="flex items-center mt-3">
                  <span className="edit-user-section_text">
                    Edit user details including name, address and other essentials.
                  </span>
                </div>
              </div>
              <div className="edit-user-section_content">
                {!(isStudent || isParent || isTeacher) && (
                  <Input
                    onChange={(e) => {
                      setUser((prev) => ({ ...prev, name: e.target.value }));
                    }}
                    label="Name"
                    name="name"
                    value={user?.name ?? ''}
                    customStyle={{
                      width: '30.25rem',
                      height: '2.5rem',
                      fontSize: '0.875rem',
                      borderRadius: '0.25rem',
                    }}
                    error={error.statusFullName}
                  />
                )}
                {(isStudent || isParent || isTeacher) && (
                  <div className="flex">
                    <Input
                      onChange={(e) => {
                        setUser((prev) => ({ ...prev, firstName: e.target.value }));
                      }}
                      label="First Name"
                      name="first-name"
                      value={user?.firstName ?? ''}
                      customStyle={{
                        width: '14.25rem',
                        height: '2.5rem',
                        fontSize: '0.875rem',
                        borderRadius: '0.25rem',
                      }}
                      error={error.statusFirstName}
                    />
                    <Input
                      onChange={(e) => {
                        setUser((prev) => ({ ...prev, lastName: e.target.value }));
                      }}
                      label="Last Name"
                      name="last-name"
                      value={user?.lastName ?? ''}
                      labelFix="left-10"
                      customStyle={{
                        width: '14.25rem',
                        height: '2.5rem',
                        marginLeft: '1.75rem',
                        fontSize: '0.875rem',
                        borderRadius: '0.25rem',
                      }}
                      error={error.statusLastName}
                    />
                  </div>
                )}
                <Input
                  onChange={(e) => {
                    setUser((prev) => ({ ...prev, email: e.target.value }));
                  }}
                  label="Email"
                  name="email"
                  value={user?.email ?? ''}
                  customStyle={{
                    width: '30.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                  error={error.statusEmail}
                  disabled
                />
                <Input
                  onChange={(e) => {
                    setUser((prev) => ({ ...prev, phoneNumber: e.target.value }));
                  }}
                  label="Phone Number"
                  name="phone"
                  value={user?.phoneNumber ?? ''}
                  customStyle={{
                    width: '30.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                  error={error.statusPhoneNumber}
                  disabled
                />
              </div>
              {isParent && (
                <div className="edit-user-section_header">
                  <h2 className="edit-user-section_title">3. Family Details</h2>
                  <div className="mt-2" style={{ width: '30.25rem' }}>
                    <p className="edit-user-section_text mb-2">
                      Add the children information below
                    </p>
                    <SelectGroup
                      className="text-sm"
                      title="Children"
                      placeholder="Select children"
                      value={!!listStudentOfParent[0]?.value ? listStudentOfParent : null}
                      styles={selectStylesWithTitle}
                      options={listStudent}
                      formatOptionLabel={formatOptionLabelChildren}
                      isMulti={true}
                      isSearchable={true}
                      onChange={setListStudentOfParent}
                    />
                  </div>
                </div>
              )}
              {!isParent && (
                <>
                  {dataLocationAndRole.length > 0 &&
                    dataLocationAndRole.map(
                      ({ location, role, isErrorLocation, isErrorRole }, index) => {
                        return (
                          <div key={index} className="flex items-center gap-x-4 max-h-26">
                            <SelectGroup
                              error={isErrorLocation}
                              classNameGroup="mt-6 mb-6 text-sm z-3"
                              customStyle={{ width: '15.25rem' }}
                              title="Location"
                              placeholder=""
                              labelFix={{ left: 5, top: 3, fontSize: '120%' }}
                              value={location}
                              options={listLocations.map((location) => {
                                const exclude = dataLocationAndRole.map(item => item?.location?.value);
                                return {
                                  ...location,
                                  options: location.options.filter(location => !exclude.includes(location.value))
                                }
                              })}
                              isMulti={false}
                              isSearchable={true}
                              formatOptionLabel={formatOptionLabelUser}
                              styles={{
                                ...selectStylesApprovers,
                                singleValue: (styles, { data }) => {
                                  return {
                                    ...styles,
                                    backgroundColor: data.color || '#404EED',
                                    color: 'white',
                                    borderRadius: '25px',
                                    paddingTop: '0.25rem',
                                    paddingBottom: '0.25rem',
                                  };
                                },
                              }}
                              onChange={(e) => {
                                setDataLocationAndRole((prev) => {
                                  prev[index].location = e;
                                  return [...prev];
                                });
                              }}
                            />
                            <SelectGroup
                              error={isErrorRole}
                              classNameGroup="mt-6 mb-6 text-sm z-3"
                              customStyle={{ width: '30.25rem' }}
                              title="Role"
                              placeholder=""
                              labelFix={{ left: 5, top: 3, fontSize: '120%' }}
                              value={role}
                              options={listRoles}
                              isMulti={true}
                              isSearchable={true}
                              styles={selectStylesWithTitle}
                              onChange={(e) => {
                                setDataLocationAndRole((prev) => {
                                  prev[index].role = e;
                                  return [...prev];
                                });
                              }}
                            />
                            <IconButton onClick={() => onRemove(index)}>
                              <ClearIcon style={{ color: 'red' }} />
                            </IconButton>
                          </div>
                        );
                      },
                    )}
                </>
              )}
              <div
                className={
                  'rounded-md shadow-standart my-0 mx-auto w-[30rem] h-12 mt-5 flex'
                }
              >
                <Button
                  text="Add Location"
                  customStyle={styles.btnAddLocation}
                  className={
                    'border-none w-full text-main-black text-sm font-medium leading-[22px]'
                  }
                  styleIcon={'w-8 h-8'}
                  icon={newPlus}
                  onClick={() => {
                    setDataLocationAndRole((prev) => [
                      ...prev,
                      {
                        location: null, // save the input value at this location
                        role: [], // save the input value at this role
                      },
                    ]);
                  }}
                />
              </div>

              <div className="edit-user-section_footer">
                <Button
                  text="Cancel"
                  customStyle={styles.btnWhite}
                  onClick={() => {
                    handlerOnClickCancel();
                  }}
                />
                <Button
                  text="Save"
                  customStyle={styles.btnBlue}
                  onClick={() => {
                    handleOnClickSave();
                  }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
