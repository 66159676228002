import { handleCallApiFail } from '@helper/call-api';
import { Card } from '@mui/material';
import { Button } from '@stories/index';
import {
  checkErrorApiFetch,
  checkErrorMultipleApi,
} from '@utils/check-error/api-error';
import { deleteKeysInObject, keyRelationship } from '@utils/utils';
import clsx from 'clsx';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import AddFeeDialog from './components/add-fee-dialog';
import ScholarshipItem from './scholarship-item';
import financeApi from 'api/finance';
import mediaApi from 'api/media';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';
import { initScholarship } from '@utils/constant';

const objValueType = {
  Percentage: 'Percentage',
  Amount: 'Amount'
}

const optionFees = [
  { value: 'Admission Fee', label: 'Admission Fee' },
  { value: 'Tuition Fee', label: 'Tuition Fee' },
  { value: 'Residential Fee', label: 'Residential Fee' },
  { value: 'Food Fee', label: 'Food Fee' },
  { value: 'Transport Fee', label: 'Transport Fee' },
  { value: 'Custom Fee', label: 'Custom Fee' }
]

function Scholarship(props) {
  const { applicationId, isParent, enquiry, isCompletedOrClosed, handleNextStage, handleRefreshEnquiry } = props;

  const [scholarships, setScholarships] = useState();
  const [scholarshipOriginal, setScholarshipOriginal] = useState()
  const [extraInfo, setExtraInfo] = useState()
  const [refresh, setRefresh] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [admissionFee, setAdmissionFee] = useState()
  const [openDialog, setOpenDialog] = useState(-1)
  const [feeInfo, setFeeInfo] = useState()
  const [scholarshipEnables, setScholarshipEnables] = useState({})
  const campus = useStorage(state => state.currentCampus)

  const getSiblingDiscount = (listSibling, siblingCount) => {
    const infoSibling = { name: '', discountType: "Fixed Amount", value: '' }
    if (!listSibling || !siblingCount) return infoSibling
    switch (siblingCount) {
      case 1:
        return listSibling.find(item => item.name === '1st Sibling')
      case 2:
        return listSibling.find(item => item.name === '2nd Sibling')
      case 3:
        return listSibling.find(item => item.name === '3rd Sibling')
      default:
        return listSibling.find(item => item.name === '3rd Sibling')
    }
  }

  useEffect(() => {
    financeApi.getListScholarship({ campusId: enquiry?.campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Scholarship Finance ',
          )
        ) {
          const tmpScholarshipEnables = { partnerSchool: true, partnerDiscount: true }
          const { academicScholarship, boardDiscount, partnerDiscount, principalDiscount, sibblingDiscount, sportsScholarship } = res.data.data
          if (JSON.parse(academicScholarship).active)
            tmpScholarshipEnables.academic = true
          if (JSON.parse(boardDiscount).active)
            tmpScholarshipEnables.boardFund = true
          if (JSON.parse(principalDiscount).active)
            tmpScholarshipEnables.principalFund = true
          if (JSON.parse(sportsScholarship).active)
            tmpScholarshipEnables.sports = true
          if (JSON.parse(sibblingDiscount).active)
            tmpScholarshipEnables.siblingScholarship = true
          setScholarshipEnables(tmpScholarshipEnables)
          const listSibling = JSON.parse(sibblingDiscount).items.map(item => ({ ...item, label: item.name, idOption: item.name }))
          let siblingCount = 1;
          if (enquiry?.admissionResult?.info) {
            const info = JSON.parse(enquiry.admissionResult.info)
            if (info?.application?.applicationParent) {
              siblingCount = info.application.applicationParent.reduce((count, item) => {
                if (item.relationship === keyRelationship.sibling) return count + 1
                return count
              }, 1)
            }
          }
          let listPartnerSchool = []
          let listPartnerCompany = []
          for (let discount of JSON.parse(partnerDiscount).items) {
            const option = { ...discount, label: discount.partnerName, idOption: discount.partnerName }
            if (discount.partnerType === 'Cooperate')
              listPartnerSchool.push(option)
            if (discount.partnerType === 'Company')
              listPartnerCompany.push(option)
          }
          setExtraInfo({ sibling: getSiblingDiscount(listSibling, siblingCount), listPartnerSchool, listPartnerCompany })
        }
      })
      .catch((error) => {
        handleCallApiFail(
          error,
          'Get Data Failed',
          setToastMessage,
          setIsShowToastMessage,
        );
      });
  }, []);

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('mediaId', file.name);
    return mediaApi.uploadFile(formData);
  }

  useEffect(() => {
    const fetch = async () => {
      if (applicationId) {
        let api = admissionApi.getListScholarship({ applicationId })
        if (isParent)
          api = admissionApi.getListScholarshipParent({ applicationId })
        const res = await api;
        if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const initial = {
            documents: [],
            urlDocuments: [],
            info: { comment: '', fees: [{}] }
          }
          const tmp = res.data.data.map(item => {
            if (item?.id) return {
              ...item,
              info: item.info ? JSON.parse(item.info) : { comment: '', fees: [{}] },
              documents: JSON.parse(item.documents),
              urlDocuments: Array(JSON.parse(item.documents).length).fill(0)
            }
            return JSON.parse(JSON.stringify(initial))
          })
          setScholarships(tmp.length === 0 ? [initial] : JSON.parse(JSON.stringify(tmp)));
          setScholarshipOriginal(JSON.stringify(tmp))
        }
      }
    }
    fetch()
  }, [applicationId, refresh, isParent])

  const extractFee = (dataInput) => {
    try {
      const { data } = dataInput
      const fee = {}
      if (data?.admissionFee) {
        const info = JSON.parse(data.admissionFee)
        const { isActive, price, type } = info
        fee.admissionFee = isActive ? Number(price) || 0 : 0
      }
      if (data?.customFees) {
        const info = JSON.parse(data.customFees)
        fee.customFees = Number(info.estAnnualFee) || 0
      }
      if (data?.transportFee) {
        const info = JSON.parse(data?.transportFee)
        fee.transportFee = Number(info.estAnnualFee) || 0
      }
      if (data?.foodFee) {
        const info = JSON.parse(data?.foodFee)
        fee.foodFee = Number(info.estAnnualFee) || 0
      }
      if (data?.residentialFeeForClasses) {
        const info = JSON.parse(data.residentialFeeForClasses)
        const { isActive, type, price } = info
        if (isActive && type === 'Same') {
          fee.residentialFee = Number(price) || 0
        } else {
          if (data?.residentialFee) {
            const info = JSON.parse(data.residentialFee?.info || "{}")
            fee.residentialFee = Number(info.estAnnualFee) || 0
          }
        }
      }
      if (data?.tutionFees?.info) {
        const info = JSON.parse(data.tutionFees.info)
        fee.tuitionFees = info.estAnnualFee
      }
      return fee
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Get Fees',
        message: error.response?.data?.message || error
      })
      setIsShowToastMessage(true)
    }
  }

  useEffect(() => {
    const fetch = async (signal) => {
      const res = await admissionApi.getListFeeBook({ campusId: campus?.id, applicationId }, signal)
      let fee
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
        fee = extractFee(res.data)
      }
      setAdmissionFee(fee)
    }
    const controller = new AbortController();
    if (!isParent) fetch(controller.signal)
    return () => {
      controller.abort()
    }
  }, [])

  const onOpenDialog = (index) => {
    setOpenDialog(index)
  }

  const onCloseDialog = () => {
    setOpenDialog(-1)
  }

  const optionFeeFiltered = useMemo(() => {
    if (openDialog === -1 || !scholarships) return []
    const feesExisted = {}
    for (let fee of scholarships[openDialog].info.fees) {
      const { name } = fee
      if (!(name in feesExisted)) feesExisted[name] = name
    }
    return optionFees.filter(fee => !(fee.value in feesExisted))
  }, [openDialog, scholarships])

  const onAddScholarship = () => {
    setScholarships(prev => [...prev, JSON.parse(JSON.stringify(initScholarship))])
    setFeeInfo()
  }

  const getDocuments = () => {
    const listFile = []
    for (let scholarship of scholarships) {
      const { documents } = scholarship
      if (documents && documents.length !== 0) {
        documents.forEach(document => {
          if (document.name) listFile.push(document)
        })
      }
    }
    return listFile
  }

  const uploadDocuments = async () => {
    const documents = getDocuments()
    if (documents.length !== 0) {
      const res = await Promise.all(documents.map(file => uploadFile(file)))
      if (checkErrorMultipleApi(res, setToastMessage, setIsShowToastMessage, '', false)) {
        setScholarships(prev => {
          for (let scholarship of prev) {
            const { documents: documentsScholarship, urlDocuments } = scholarship
            if (documentsScholarship && documentsScholarship.length !== 0) {
              for (let index in documentsScholarship) {
                if (documentsScholarship[index].name) {
                  documentsScholarship[index] = res.shift().data.data
                  URL.revokeObjectURL(urlDocuments[index])
                  urlDocuments[index] = ''
                }
              }
            }
          }
          return [...prev]
        })
      }
    }
  }

  const getScholarshipAction = () => {
    const scholarshipsCopy = JSON.parse(JSON.stringify(scholarships))
    const listScholarshipDelete = []
    const listScholarshipNewOrUpdate = []
    scholarshipsCopy.forEach(scholarship => {
      const { isDeleted } = scholarship;
      if (isDeleted) {
        const newScholarship = deleteKeysInObject({ obj: scholarship, listKey: ['urlDocuments', 'isUpdate', 'isDeleted'] })
        newScholarship.info = JSON.stringify(newScholarship.info)
        listScholarshipDelete.push(newScholarship)
      } else {
        const newScholarship = deleteKeysInObject({ obj: scholarship, listKey: ['urlDocuments', 'isUpdate', 'isDeleted'] })
        newScholarship.info = JSON.stringify(newScholarship.info)
        listScholarshipNewOrUpdate.push(newScholarship)
      }
    })
    return { listScholarshipDelete, listScholarshipNewOrUpdate }
  }

  const getEstFee = (feeName) => {
    switch (feeName) {
      case "Admission Fee":
        return admissionFee.admissionFee
      case "Tuition Fee":
        return admissionFee.tuitionFees
      case "Residential Fee":
        return admissionFee.residentialFee
      case "Food Fee":
        return admissionFee.foodFee
      case "Transport Fee":
        return admissionFee.transportFee
      case "Custom Fee":
        return admissionFee.customFees
      default:
        return 0
    }
  }

  const onAddFee = (feeName) => {
    onCloseDialog()
    let newFee = { active: true, name: feeName, value: '', valueType: objValueType.Amount, estFee: getEstFee(feeName) }
    if (feeInfo)
      newFee = {
        active: true,
        name: feeName,
        value: feeInfo.value,
        valueType: feeInfo.discountType.includes(objValueType.Amount) ? objValueType.Amount : objValueType.Percentage,
        estFee: getEstFee(feeName)
      }
    setScholarships(prev => {
      prev[openDialog].info.fees = [...prev[openDialog].info.fees, newFee]
      return [...prev]
    })
  }

  const handleCallApi = async ({ listScholarshipNewOrUpdate, listScholarshipDelete }) => {
    let newListScholarShipNewOrUpdate = listScholarshipNewOrUpdate
    let newListScholarShipDelete = listScholarshipDelete
    if (isParent) {
      newListScholarShipNewOrUpdate = listScholarshipNewOrUpdate.map(item => {
        if (item.info) {
          delete item.info
          return item
        }
        return item
      })
      newListScholarShipDelete = listScholarshipDelete.map(item => {
        if (item.info) {
          delete item.info
          return item
        }
        return item
      })
    }
    const apis = []
    const campusId = campus?.isCentral ? undefined : campus?.id
    if (listScholarshipDelete.length !== 0) {
      if (isParent) {
        apis.push(admissionApi.deleteApplicationScholarshipParent({ campusId }, { applicationId, scholarships: newListScholarShipDelete }, applicationId))
      } else
        apis.push(admissionApi.deleteApplicationScholarship({ campusId }, { applicationId, scholarships: newListScholarShipDelete }, applicationId))
    }
    if (listScholarshipNewOrUpdate.length !== 0) {
      if (isParent) {
        apis.push(admissionApi.updateApplicationScholarshipParent({ campusId }, { applicationId, scholarships: newListScholarShipNewOrUpdate }))
      } else
        apis.push(admissionApi.updateApplicationScholarship({ campusId }, { applicationId, scholarships: newListScholarShipNewOrUpdate }))
    }
    if (apis) {
      const res = await Promise.all(apis)
      if (checkErrorMultipleApi(res, setToastMessage, setIsShowToastMessage)) {
        setRefresh((prev) => !prev);
        handleRefreshEnquiry();
      }
    }
  }

  const handleRequestApproval = async () => {
    try {
      if (isCompletedOrClosed)
        return handleNextStage()
      await uploadDocuments()
      const { listScholarshipNewOrUpdate, listScholarshipDelete } = getScholarshipAction()
      await handleCallApi({ listScholarshipNewOrUpdate, listScholarshipDelete })
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Update Scholarship',
        message: error,
      });
      setIsShowToastMessage(true);
    }
  }

  if (!scholarships || scholarships.length === 0 || (!isParent && !admissionFee)) return <Spinner />
  return (
    <div>
      <div className="flex items-center justify-between max-w-[38.575rem] 1200px:w-[96%] 1599px:w-[68%] mx-auto">
        <div className="mb-10">
          <h3 className="font-medium text-2xl mb-3">Scholarship</h3>
          <p className="text-xs">
            If the candidate qualifies for a scholarship please update relevant documents
            to check eligibility
          </p>
        </div>
      </div>
      {scholarships.length > 0 &&
        scholarships.map((data, idx) => {
          return (
            <div className="1200px:w-[96%] 1599px:w-[64%] 1599px: max-w-[42.575rem] mx-auto" key={idx}>
              <Card sx={{
                borderRadius: '0.5rem',
                boxShadow: '0px 12px 24px 0px #919EAB3D, 0px 0px 2px 0px #919EAB3D',
                marginBottom: '3rem',
                padding: '0.5rem 2rem'
              }}>
                <ScholarshipItem
                  data={data}
                  idxScholarship={idx}
                  isParent={isParent}
                  admissionFee={admissionFee}
                  extraInfo={extraInfo}
                  objValueType={objValueType}
                  scholarshipEnables={scholarshipEnables}
                  scholarshipOriginal={scholarshipOriginal}
                  setScholarships={setScholarships}
                  setFeeInfo={setFeeInfo}
                  onOpenDialog={onOpenDialog}
                />
              </Card>
            </div>
          );
        })}

      {/* TODO: replace === => !== */}
      {
        enquiry.status !== EnquiryStatus.COMPLETE && (
          <div className="flex justify-center my-10">
            <Button buttonDisabled={isCompletedOrClosed}
              onClick={onAddScholarship}
              text="Add an another Scholarship"
              buttonStyle="outlined"
              customStyle={{ width: '17rem', paddingLeft: '1.5rem', paddingRight: '0.5rem' }}
            />
          </div>
        )
      }
      <div className={clsx('flex items-center justify-center mt-3.5 mb-6 justify-end')}>
        <Button
          onClick={handleNextStage}
          text="Skip Step"
          buttonStyle="outlined"
          customStyle={{ width: '15rem', paddingLeft: '5rem', paddingRight: '2.5rem' }}
        />
        <Button
          text={isParent ? 'Request Approval' : "Save"}
          // buttonDisabled={!isEdit}
          customStyle={{
            paddingLeft: '3.438rem',
            paddingRight: '3.438rem',
            marginLeft: '1.25rem',
          }}
          onClick={handleRequestApproval}
        />
      </div>
      <AddFeeDialog open={openDialog > -1}
        onClose={onCloseDialog}
        optionFees={optionFeeFiltered}
        handleAdd={(feeName) => onAddFee(feeName)} />
    </div >
  );
}

export default Scholarship;
