import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import {
  ExpandIcon,
  FilterIcon,
  SearchIcon,
  SortIcon,
} from '../../../components/admission-module/application/application-window/application-window';
import StatsItem from '../../../components/admission-module/dashboard/admission-module-stats/admission-module-stats_item/stats-item';
import { ToastMessageContext } from '../../../context/toast-context';
import { dataLinks } from '../../../entities/routes';
import { callApi } from '../../../helper/call-api';
import { useDebounce } from '../../../hooks';
import { asc, desc, mainavatar } from '../../../static/image';
import Pagination from '../../../stories/pagination/pagination';
import { rowsPerPageCount } from '../../../utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '../../../utils/utils';
import { NavLinks } from '../../Header/components/index';
import Table from './components/table';
import Loading from '@components/loading';
import './request.scss';

const types = ['In Progress', 'History'];
const listDataSort = [
  { status: 'Date Created', value: 'dateCreated' },
  { status: 'Process Name', value: 'processName ' },
  { status: 'Request Name', value: 'requestName' },
];

export default function Approvals({ burger }) {
  const [requestStatistics, setRequestStatistics] = useState({});
  const [type, setType] = useState(types && types[0]);
  const [requests, setRequests] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [orderDirection, setOrderDirection] = useState();
  const [orderBy, setOrderBy] = useState();
  const [loading, setLoading] = useState(true);
  const [sortValue, setSortValue] = useState('dateCreated');
  const [orderByValue, setOrderByValue] = useState('asc');
  const [searchValue, setSearchValue] = useState('');
  const [isOpenModalSort, setIsOpenModalSort] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);

  const debouncedSearch = useDebounce(searchValue, 700);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const searchValueInput = e.target.value;
    if (searchValueInput.startsWith(' ')) {
      return;
    }
    // setRequests([]);
    setCurrentPage(0);
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const res = callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/statistics`,
    });
    res
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setRequestStatistics(data.data);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get Request Statistics Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Request Statistics Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Approval Request Error', error);
      });
  }, [isUpdate]);

  useEffect(() => {
    setLoading(true);
    // if (prevSortValue === sortValue && prevOrderByValue === orderByValue) {
    //   return;
    // }
    let urlCallApi;
    if (type === 'In Progress') {
      urlCallApi = `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/list/in-progress`;
    } else if (type === 'History') {
      urlCallApi = `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/list/history`;
    }

    callApi({
      method: 'get',
      url: urlCallApi,
      params: {
        page: currentPage,
        pageSize: selectedRowsPerPage,
        orderDirection,
        orderBy,
        textSearch: searchValue,
      },
    })
      .then((res) => {
        if (!res.data.success) {
          setToastMessage({
            status: 'error',
            title: 'Get List Request Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          return;
        }
        setRequests(res.data.data.objects);
        setTotalObjectsFromApi(res.data.data.total);
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List Request Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Approval Request Error', error);
      }).finally(() => {
        setLoading(false);
      });
  }, [type, isUpdate, currentPage, selectedRowsPerPage, debouncedSearch, isSort]);

  const inProgressHandle = (currentApproval) => {
    const tbodyData = currentApproval.map((approval) => {
      const users = approval.stage.processStageApprover.map((user) => {
        let stageApproverResult;
        const checkApprovalResult = user?.processStageApproverResult?.status
          ? user.processStageApproverResult.status
          : '';
        if (checkApprovalResult === 'approve') {
          stageApproverResult = 'Approve';
        } else if (checkApprovalResult === 'reject') {
          stageApproverResult = 'Reject';
        } else {
          stageApproverResult = 'Need Info';
        }
        return {
          avatar: user?.approver?.photoURL ? user.approver.photoURL : mainavatar,
          isApproval: stageApproverResult,
          userId: user?.approver?.uid ? user.approver.uid : '',
          processStageApproverId: user?.id ? user.id : '',
        };
      });
      return {
        avatar: approval?.request?.userRequest?.photoURL
          ? approval.request.userRequest.photoURL
          : mainavatar,
        requestName: approval?.request?.name ? approval.request.name : 'Request Name',
        code: approval?.request?.id ? approval.request.id : '',
        users: users,
        processName: approval?.process?.name ? approval.process.name : 'Process Name',
        processColor: approval?.process?.color ? approval.process.color : '#637381',
        multiple:
          approval?.stage?.approvalType && approval.stage.approvalType === 'single'
            ? false
            : true,
        dueOn: approval.stage.dueOn,
      };
    });
    return tbodyData;
  };

  const historyHandle = (currentApproval) => {
    const tbodyData = currentApproval.map((approval) => {
      const users = approval.stage.processStageApprover.map((user) => {
        let stageApproverResult;
        const checkApprovalResult = user?.processStageApproverResult?.status
          ? user.processStageApproverResult.status
          : '';
        if (checkApprovalResult === 'approve') {
          stageApproverResult = 'Approve';
        } else if (checkApprovalResult === 'reject') {
          stageApproverResult = 'Reject';
        } else {
          stageApproverResult = 'Need Info';
        }
        return {
          avatar: user?.approver?.photoURL ? user.approver.photoURL : mainavatar,
          isApproval: stageApproverResult,
          userId: user?.approver?.uid ? user.approver.uid : '',
          processStageApproverId: user?.id ? user.id : '',
        };
      });
      return {
        avatar: approval?.request?.userRequest?.photoURL
          ? approval.request.userRequest.photoURL
          : mainavatar,
        requestName: approval?.request?.name ? approval.request.name : 'Request Name',
        code: approval?.request?.id ? approval.request.id : '',
        users: users,
        processName: approval?.process?.name ? approval.process.name : 'Process Name',
        processColor: approval?.process?.color ? approval.process.color : '#637381',
        multiple:
          approval?.stage?.approvalType && approval.stage.approvalType === 'single'
            ? false
            : true,
        dueOn: approval.stage.dueOn,
        stageResult: approval?.stage?.status ? approval.stage.status.trim() : 'need info',
        resultDate: approval.stage.resultDate,
      };
    });
    return tbodyData;
  };

  const tbodyData =
    type === 'In Progress' ? inProgressHandle(requests) : historyHandle(requests);

  return (
    <div>
      <div
        className={clsx('approvals px-24 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}
      >
        <div className="flex mb-12">
          <div className="flex-auto">
            <NavLinks urls={dataLinks.ApprovalRequestsLinks} />
            <h1 className="namepage">Approvals</h1>
            <div className="mb-5">
              {types.map((item, key) => (
                <button
                  className={clsx(
                    'mr-7 h-12 border-b-2 transition-all none-after',
                    item === type ? 'border-main-blue' : 'border-white',
                  )}
                  onClick={() => {
                    setCurrentPage(0);
                    setType(item);
                    setSearchValue('');
                    setOrderByValue('asc');
                    setSortValue('dateCreated');
                    setRequests([]);
                  }}
                  key={key}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          <div className="flex-right">
            <div className="flex items-center pt-1 pl-2">
              <ul className="approvals-stats">
                <StatsItem
                  num={requestStatistics.completed}
                  title="Completed"
                  textColor="#212B36"
                />
                <StatsItem
                  num={requestStatistics.pending}
                  title="Pending"
                  textColor="#FFD964"
                />
                <StatsItem
                  num={requestStatistics.pastDeadline}
                  title="Past Deadline"
                  textColor="#FF4343"
                />
              </ul>
            </div>
            <div className="flex items-center pt-1 pl-2 relative">
              <SearchIcon />
              <input
                value={searchValue}
                onChange={(e) => {
                  handleChangeInput(e);
                }}
                placeholder="Search"
                className="mx-2 w-40 text-base focus:outline-none"
              />
              <div
                onClick={() => {
                  setIsOpenModalSort((prev) => !prev);
                }}
                className="cursor-pointer"
              >
                <SortIcon />
              </div>
              <p
                onClick={() => {
                  setIsOpenModalSort((prev) => !prev);
                }}
                className="w-20 ml-3 mt-1 cursor-pointer"
              >
                Sort
              </p>
              <div className="flex items-center pt-1 pl-2 approvals-filter">
                <FilterIcon />
                <p className="w-20 ml-3 mt-1 mb-2">Filter / 1</p>
                <ExpandIcon />
              </div>
              {isOpenModalSort && (
                <div className="absolute top-[3rem] left-[10rem] w-40 z-20 bg-[#fff] shadow-2xl rounded">
                  <ul className="p-1">
                    <li>
                      <div className="flex justify-around">
                        <img
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOrderByValue('asc');
                            setSortValue('');
                          }}
                          className={clsx(
                            'w-[2rem] m-2 text-center rounded text-sm py-1',
                            orderByValue === 'asc' ? 'opacity-100' : 'opacity-30',
                          )}
                          src={asc}
                          alt="asc"
                        />
                        <img
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOrderByValue('desc');
                            setSortValue('');
                          }}
                          className={clsx(
                            'w-[2rem] m-2 text-center rounded text-sm py-1 rotate-90',
                            orderByValue === 'desc' ? 'opacity-100' : 'opacity-30',
                          )}
                          src={desc}
                          alt="desc"
                        />
                      </div>
                    </li>
                    <li>
                      <hr />
                    </li>
                    {listDataSort.map((data, index) => {
                      return (
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSortValue(data.value);
                            // setPrevSortValue(sortValue)
                            setOrderBy(data.value);
                            setOrderDirection(orderByValue);
                            setIsOpenModalSort(false);
                            setIsSort((prev) => !prev);
                            // setListTasks([]);
                            // setPage(0);
                          }}
                          key={index}
                          className={clsx(
                            'mx-4 my-4 rounded text-xs py-1 cursor-pointer',
                            data.value === sortValue ? 'text-[#404eed]' : '',
                          )}
                          style={{ color: '#333' }}
                        >
                          {data.status}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? <div className='h-40 flex items-center justify-center'><Loading /></div> : totalObjectsFromApi !== 0 ? (
          tbodyData.map((approval, index) => {
            return (
              <Table
                key={approval.code}
                approval={approval}
                type={type}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            );
          })
        ) : (
          <h3 className="text-rose-600">Have no request</h3>

        )}
        {/* {totalObjectsFromApi !== 0 ? (
          tbodyData.map((approval, index) => {
            return (
              <Table
                key={approval.code}
                approval={approval}
                type={type}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            );
          })
        ) : (
          <h3 className="text-rose-600">Have no request</h3>

        )} */}
      </div>
      <div className="h-20"></div>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-0 right-0 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
}
